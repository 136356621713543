import { observable, configure, action, computed, toJS } from 'mobx';
// import StoreModel from 'preact-storemodel';
import LocalModel from './localModel';
import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

import localUtil from '../lib/util';

const isDevelopment = process.env.NODE_ENV === 'development';

configure({ enforceActions: 'always' });

class UserStore extends LocalModel {
    constructor() {
        super('user', {
            namePlural: 'users',
            sort: 'title',
            limit: 100,
            api: {
                search: {
                    url: '/api/users/',
                    params: {
                        extendedView: 1,
                        limit: 15,
                        sort: 'id',
                    },
                },
                load: {
                    url: '/api/users/',
                    params: {},
                },
                save: {
                    url: '/api/users/',
                    params: {},
                },
                delete: {
                    url: '/api/users/',
                    params: {},
                },
            },
        });
    }

    @observable getInfoEpoch = 0;

    @observable isLoading = {};

    @observable newUser = {};

    @observable adminUser = {};

    @observable user = {};

    @observable customer = {};

    @observable currentEmail = '';

    @observable currentCellphone = '';

    @observable simulatedEmail = '';

    @observable isAdmin = false;

    @observable isEditor = false;

    @observable isTester = false;

    @observable users = [];

    @observable adminUserList = [];

    @observable countries = [];

    @observable allUsers = [];

    @observable emailIsAvailable = false;

    @observable twoFactorLogin = false;

    @observable emailMessage = '';

    @observable emailMessageIcon = '';

    @observable emailError = '';

    @observable emailErrorIcon = '';

    @observable subscriptionPaypal = {};

    @observable yrWeather = {};

    @observable yrWeatherFormatted = {};

    @observable yrWeatherKeys = [];

    @observable favorites = [];

    // Cordova variables
    bgGeo = null;

    @observable cordovaAuthorizationStatus = null;

    @observable powerSaveStatus = false;

    @observable providerState = false;

    @observable register = {
        messageIcon: '',
        message: '',
        errorIcon: '',
        error: '',
    };

    @observable login = {
        showToken: false,
        messageIcon: '',
        message: '',
        errorIcon: '',
        error: '',
    };

    @observable loginLink = {
        emailSent: false,
        messageIcon: '',
        message: '',
        errorIcon: '',
        error: '',
    };

    @observable currentLocation = null;

    @observable loadMore = () => {};

    @action
    cleanupMemory() {
        if (isDevelopment) {
            console.log('UserStore.cleanupMemory');
        }
        this.localUpdateField('searchUsersResult', []);
        this.localUpdateField('searchUserTeams', []);
    }

    @action
    setPowerSaveStatus(status = false) {
        this.powerSaveStatus = status;
    }

    @action
    setProviderState(state = false) {
        this.providerState = state;
    }

    @action
    setGetInfoEpoch(epoch = new Date().getTime()) {
        this.getInfoEpoch = epoch;
    }

    @action
    setLoadMore(func) {
        if (util.isFunction(func)) {
            this.loadMore = func;
        }
    }

    @action
    updateCurrentLocation(location) {
        this.currentLocation = location
    }

    @action
    setLoading(name, state = false) {
        this.isLoading[name] = state;
        if (!this.isLoading[name]) {
            delete this.isLoading[name];
        }
    }

    @action
    localUpdateField(key, value) {
        this[key] = value;
    }

    @action
    updateUserField(key, value) {
        this.user[key] = value;
    }

    @action
    updateUserSubValue(key, value) {
        const keyParts = key.split('.');
        // console.log({ key, value, keyParts });
        if (!this.user[keyParts[0]]) {
            this.user[keyParts[0]] = {};
        }
        this.user[keyParts[0]][keyParts[1]] = value;
    }

    @action
    updateObjectField(object, key, value) {
        this[object][key] = value;
    }

    @action
    resetRegisterMessages() {
        this.register = {
            messageIcon: '',
            message: '',
            errorIcon: '',
            error: '',
        };
    }

    @action
    resetLoginMessages() {
        this.login = {
            showToken: false,
            messageIcon: '',
            message: '',
            errorIcon: '',
            error: '',
        };
    }

    @action
    resetLoginLinkMessages() {
        this.loginLink = {
            emailSent: this.loginLink.emailSent,
            messageIcon: '',
            message: '',
            errorIcon: '',
            error: '',
        };
    }

    async registerUser({ email, password, fingerprint, jwtToken, skipPubSub = false, language = 'en' }) {
        const isValidEmail = util.validateEmail(email);
        if (isValidEmail) {
            const registerResponse = await util.fetchApi('/api/users/register', { publish: true, method: 'POST' }, { email, password, fingerprint, jwtToken, language });
            switch (registerResponse.status) {
                case 201:
                    this.localUpdateField('user', registerResponse.data.user);
                    this.localUpdateField('emailIsAvailable', true);
                    if (language === 'no') {
                        this.localUpdateField('emailMessage', 'Du er nå registrert 😃');
                    } else {
                        this.localUpdateField('emailMessage', 'You are now registered 😃');
                    }
                    this.updateObjectField('loginLink', 'emailSent', false);
                    util.setJwtToken(registerResponse.data.apiToken);
                    util.setUserEmail(registerResponse.data.user.email);
                    if (!skipPubSub) {
                        PubSub.publish(topics.JWT_TOKEN_CHANGED, registerResponse.data.apiToken);
                    }
                    break;
                case 401:
                    this.updateObjectField('register', 'errorIcon', 'fas fa-bomb');
                    if (language === 'no') {
                        this.updateObjectField('register', 'error', `Registreringen feilet! ${registerResponse.status}: ${registerResponse.message}`);
                    } else {
                        this.updateObjectField('register', 'error', `Registration failed! ${registerResponse.status}: ${registerResponse.message}`);
                    }
                    break;
                default:
                    this.updateObjectField('register', 'errorIcon', 'fas fa-bomb');
                    if (language === 'no') {
                        this.updateObjectField('register', 'error', `Registreringen feilet! ${registerResponse.status}: ${registerResponse.message}`);
                    } else {
                        this.updateObjectField('register', 'error', `Registration failed! ${registerResponse.status}: ${registerResponse.message}`);
                    }
                    break;
            }
        }
    }

    async verifyEmail({ token, language = 'en' }) {
        const verifyResponse = await util.fetchApi('/api/users/verify/email/', { publish: true, method: 'POST' }, { token, language });
        switch (verifyResponse.status) {
            case 200:
                this.localUpdateField('emailMessageIcon', 'fas fa-user-check');
                if (language === 'no') {
                    this.localUpdateField('emailMessage', 'E-postadressen er nå verifisert 😃');
                } else {
                    this.localUpdateField('emailMessage', 'Email address is verified 😃');
                }
                break;
            default:
                this.localUpdateField('emailErrorIcon', 'fas fa-bomb');
                if (language === 'no') {
                    this.localUpdateField('emailError', `Verifiseringen feilet! ${verifyResponse.status}: ${verifyResponse.message}`);
                } else {
                    this.localUpdateField('emailError', `Verification failed! ${verifyResponse.status}: ${verifyResponse.message}`);
                }
                break;
        }
    }

    async checkEmail(email, language = 'en') {
        const isValidEmail = util.validateEmail(email);
        if (isValidEmail) {
            const emailCheckResponse = await util.fetchApi('/api/users/check', { publish: true }, { email });
            switch (emailCheckResponse.status) {
                case 200:
                    this.localUpdateField('emailIsAvailable', true);
                    if (language === 'no') {
                        this.localUpdateField('emailMessage', 'E-posten er tilgjengelig 😃');
                    } else {
                        this.localUpdateField('emailMessage', 'Email is available 😃');
                    }
                    break;
                case 403:
                    this.localUpdateField('emailIsAvailable', false);
                    this.localUpdateField('twoFactorLogin', emailCheckResponse.twoFactorLogin);
                    if (language === 'no') {
                        this.localUpdateField('emailMessage', 'E-posten er allerede registert. Forsøk "Glemt passord" på loginsiden.');
                    } else {
                        this.localUpdateField('emailMessage', 'Email is already registered. Please try "Forgot password".');
                    }
                    break;
                default:
                    this.localUpdateField('emailIsAvailable', false);
                    this.localUpdateField('emailMessage', '');
                    break;
            }
        }
    }

    async checkLogin({ email, password, token, sendPubSubMessage = true, language = 'en' }) {
        const loginResponse = await util.fetchApi('/api/users/login', { method: 'POST', publish: true }, { email, password, token, language });
        switch (loginResponse.status) {
            case 200:
                this.localUpdateField('user', loginResponse.data.user);
                util.setJwtToken(loginResponse.data.apiToken);
                util.setUserEmail(loginResponse.data.user.email);
                if (sendPubSubMessage) {
                    PubSub.publish(topics.JWT_TOKEN_CHANGED, loginResponse.data.apiToken);
                }
                return true;
            case 202:
                if (loginResponse.needsTwoFactor) {
                    if (language === 'no') {
                        this.updateObjectField('login', 'message', '2FA token er påkrevd for å logge inn.');
                    } else {
                        this.updateObjectField('login', 'message', '2FA token is required to login.');
                    }
                    this.updateObjectField('login', 'showToken', true);
                }
                return false;
            case 403:
                if (loginResponse.message === 'Wrong password!') {
                    this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                    if (language === 'no') {
                        this.updateObjectField('login', 'error', `Feil passord! Dersom du har glemt passordet så kan du trykke på "Glemt passord?" helt nederst.`);
                    } else {
                        this.updateObjectField('login', 'error', `Wrong password. If you have forgotten your password, please use "Forgotten password".`);
                    }
                } else {
                    this.updateObjectField('login', 'errorIcon', 'fas fa-search');
                    if (language === 'no') {
                        this.updateObjectField('login', 'error', `Finner ikke denne brukeren, men du er velkommen til å registrere deg 😃 \nTrykk på "Ny bruker" under for å komme igang.`);
                    } else {
                        this.updateObjectField('login', 'error', `Can't find the user. You are welcome to register as a new user 😃`);
                    }
                }
                return false;
            case 422:
                this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                if (language === 'no') {
                    this.updateObjectField('login', 'error', `Innloggingen feilet! Mangler info i noen av feltene under.`);
                } else {
                    this.updateObjectField('login', 'error', `Login failed! Missing information.`);
                }
                return false;
            default:
                this.updateObjectField('login', 'errorIcon', 'fas fa-bomb');
                if (language === 'no') {
                    this.updateObjectField('login', 'error', `Innloggingen feilet! ${loginResponse.status}: ${loginResponse.message}`);
                } else {
                    this.updateObjectField('login', 'error', `Login failed! ${loginResponse.status}: ${loginResponse.message}`);
                }
                return false;
        }
    }

    async sendLoginCodeSms({ cellphone }) {
        const loginResponse = await util.fetchApi('/api/users/sendcode', { method: 'POST', publish: true }, { cellphone });
        switch (loginResponse.status) {
            case 200:
                return true;
            default:
                return false;
        }
    }

    async sendLoginCodeEmail({ email }) {
        const loginResponse = await util.fetchApi('/api/users/sendcodeemail', { method: 'POST', publish: true }, { email });
        switch (loginResponse.status) {
            case 200:
                return true;
            default:
                return false;
        }
    }

    async getInfo(props = {}) {
        const {
            loadAllUsers = false,
            skipSummary = true,
            skipStaticDataLoad = false,
            language = 'en',
            darkmode = false,
            hostname,
        } = props;
        let params = {};
        if (util.isObject(loadAllUsers)) {
            params = { ...loadAllUsers };
        }
        const { forceLoad } = params;

        const now = new Date().getTime();
        const reloadTimeMs = 1000 * 300;
        const { getInfoEpoch } = this;
        const epochDiff = now - getInfoEpoch;
        if (!forceLoad && epochDiff < reloadTimeMs) {
            // console.log('Skipping load', { epochDiff, now, getInfoEpoch, reloadTimeMs });
            return false;
        }

        let skipStaticData = skipStaticDataLoad;
        if (this.countries && this.countries.length > 0) {
            skipStaticData = true;
        }

        const infoResponse = await util.fetchApi('/api/users/info', {
            publish: true,
            method: 'GET',
        }, {
            loadAllUsers,
            skipSummary,
            skipStaticData,
            darkmode,
            hostname,
        });
        switch (infoResponse.status) {
            case 200:
                this.setGetInfoEpoch(now);
                this.localUpdateField('user', infoResponse.data.user);
                this.localUpdateField('customer', infoResponse.data.customer);
                if (!skipStaticData) {
                    this.localUpdateField('countries', infoResponse.data.countries);
                }

                this.localUpdateField('isAdmin', infoResponse.data.isAdmin);
                this.localUpdateField('isEditor', infoResponse.data.isEditor);
                this.localUpdateField('isTester', infoResponse.data.isTester);
                this.localUpdateField('currentEmail', infoResponse.data.currentEmail);
                this.localUpdateField('currentCellphone', infoResponse.data.currentCellphone);
                this.localUpdateField('simulatedEmail', infoResponse.data.simulatedEmail);
                this.localUpdateField('simulatedCellphone', infoResponse.data.simulatedCellphone);
                this.localUpdateField('build', infoResponse.data.build);
                this.localUpdateField('buildDate', infoResponse.data.buildDate);

                if (darkmode) {
                    const { settings = {} } = this.user;
                    if (settings.darkmode !== darkmode) {
                        const field = 'darkmode';
                        await this.updateSetting({ [`settings.${field}`]: settings[field] === 1 ? 0 : 1 }, { [field]: settings[field] === 1 ? 0 : 1 });
                    }
                }
                break;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
            default:
                this.updateObjectField('register', 'errorIcon', 'fas fa-bomb');
                this.updateObjectField('register', 'errorStatus', infoResponse.status);
                if (language === 'no') {
                    this.updateObjectField('register', 'error', `Henting av brukerinfo feilet! ${infoResponse.status}: ${infoResponse.message}`);
                } else {
                    this.updateObjectField('register', 'error', `Fetching user info failed! ${infoResponse.status}: ${infoResponse.message}`);
                }
                break;
        }
    }

    async getWeatherUser({ lat, lon, altitude, force }) {
        if (lat && lon) {
            const result = await util.fetchApi('/api/yr/user', { publish: true, method: 'GET' }, { lat, lon, altitude, force });
            this.updateUserField('yrWeather', result.data);
            return result;
        }
        return null;
    }

    @action
    formatWeather(weather = this.user.yrWeather) {
        this.localUpdateField('yrWeather', weather);
        const formatted = {};
        if (weather) {
            for (let i = 0, l = weather.length; i < l; i += 1) {
                const { time, instant, next_6_hours } = weather[i];
                const nightRegexp = new RegExp('T00:00');
                const morningRegexp = new RegExp('T06:00');
                const dayRegexp = new RegExp('T12:00');
                const eveningRegexp = new RegExp('T18:00');
                const date = util.isoDate(time, false, false, true);
                if (!formatted[date]) {
                    formatted[date] = {
                        air_temperatures: [],
                        wind_speeds: [],
                        precipitation_amount: 0,
                    };
                }
                if (nightRegexp.test(time)) {
                    formatted[date].air_temperatures.push(util.getNestedValue(instant, 'details.air_temperature'));
                    formatted[date].wind_speeds.push(util.getNestedValue(instant, 'details.wind_speed'));
                    formatted[date].precipitation_amount += util.getNestedValue(next_6_hours, 'details.precipitation_amount');
                    formatted[date].night = {
                        time,
                        instant,
                        next_6_hours,
                    };
                }
                if (morningRegexp.test(time)) {
                    formatted[date].air_temperatures.push(util.getNestedValue(instant, 'details.air_temperature'));
                    formatted[date].wind_speeds.push(util.getNestedValue(instant, 'details.wind_speed'));
                    formatted[date].precipitation_amount += util.getNestedValue(next_6_hours, 'details.precipitation_amount');
                    formatted[date].morning = {
                        time,
                        instant,
                        next_6_hours,
                    };
                }
                if (dayRegexp.test(time)) {
                    formatted[date].air_temperatures.push(util.getNestedValue(instant, 'details.air_temperature'));
                    formatted[date].wind_speeds.push(util.getNestedValue(instant, 'details.wind_speed'));
                    formatted[date].precipitation_amount += util.getNestedValue(next_6_hours, 'details.precipitation_amount');
                    formatted[date].day = {
                        time,
                        instant,
                        next_6_hours,
                    };
                }
                if (eveningRegexp.test(time)) {
                    formatted[date].air_temperatures.push(util.getNestedValue(instant, 'details.air_temperature'));
                    formatted[date].wind_speeds.push(util.getNestedValue(instant, 'details.wind_speed'));
                    formatted[date].precipitation_amount += util.getNestedValue(next_6_hours, 'details.precipitation_amount');
                    formatted[date].evening = {
                        time,
                        instant,
                        next_6_hours,
                    };
                }
            }
            const keys = Object.keys(formatted);
            this.localUpdateField('yrWeatherKeys', keys);

            for (let i = 0, l = keys.length; i < l; i += 1) {
                    const key = keys[i];
                    const temperatures = formatted[key].air_temperatures;
                    const wind_speeds = formatted[key].wind_speeds;
                    formatted[key].air_temperatures_min = Math.min(...temperatures);
                    formatted[key].air_temperatures_max = Math.max(...temperatures);
                    formatted[key].wind_speeds_min = Math.min(...wind_speeds);
                    formatted[key].wind_speeds_max = Math.max(...wind_speeds);
            }
            this.localUpdateField('yrWeatherFormatted', formatted);
            return weather;
        }
        return null;
    }

    async sendLoginLink(email, language = 'en') {
        const isValidEmail = util.validateEmail(email);
        if (isValidEmail) {
            const emailCheckResponse = await util.fetchApi('/api/users/login/link', { publish: true }, { email });
            switch (emailCheckResponse.status) {
                case 200:
                    this.updateObjectField('loginLink', 'emailSent', true);
                    this.updateObjectField('loginLink', 'messageIcon', 'fas fa-shipping-fast');
                    if (language === 'no') {
                        this.updateObjectField('loginLink', 'message', `E-post er sendt.`);
                    } else {
                        this.updateObjectField('loginLink', 'message', `Email sent.`);
                    }
                    return true;
                    break;
                default:
                    this.updateObjectField('loginLink', 'errorIcon', 'fas fa-bomb');
                    if (language === 'no') {
                        this.updateObjectField('loginLink', 'error', `E-postsending feilet! ${emailCheckResponse.status}: ${emailCheckResponse.message}`);
                    } else {
                        this.updateObjectField('loginLink', 'error', `Email send failed! ${emailCheckResponse.status}: ${emailCheckResponse.message}`);
                    }
                    return false;
                    break;
            }
        }
    }

    async validateLoginCode(email, code, language = 'en') {
        const isValidCode = util.validateCode(code, 1000, 999999);
        if (isValidCode) {
            const loginResponse = await util.fetchApi('/api/users/login/code', { publish: true, method: 'POST' }, { email, code });
            switch (loginResponse.status) {
                case 200:
                    this.localUpdateField('user', loginResponse.data.user);
                    this.updateObjectField('loginLink', 'emailSent', false);
                    util.setJwtToken(loginResponse.data.apiToken);
                    util.setUserEmail(loginResponse.data.user.email);
                    PubSub.publish(topics.JWT_TOKEN_CHANGED, loginResponse.data.apiToken);
                    break;
                case 403:
                    if (loginResponse.message === 'Wrong code!') {
                        this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                        if (language === 'no') {
                            this.updateObjectField('login', 'error', `Feil code! Husk at du må bruke den nyeste e-posten med koder.`);
                        } else {
                            this.updateObjectField('login', 'error', `Wrong code! Remember to use the latest email.`);
                        }
                    } else if (loginResponse.message === 'Login code has expired!') {
                        this.updateObjectField('loginLink', 'emailSent', false);
                        this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                        if (language === 'no') {
                            this.updateObjectField('login', 'error', `Koden har gått ut på dato. Be om en ny og forsøk igjen.`);
                        } else {
                            this.updateObjectField('login', 'error', `Code expired. Try to get a new code and try again.`);
                        }
                    } else {
                        this.updateObjectField('login', 'errorIcon', 'fas fa-bomb');
                        if (language === 'no') {
                            this.updateObjectField('login', 'error', `Det er noe feil med koden din. Du kan forsøke å be om en ny og prøve igjen.`);
                        } else {
                            this.updateObjectField('login', 'error', `Something wrong with your code! Try to get a new code and try again.`);
                        }
                    }
                    break;
                case 422:
                    this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                    if (language === 'no') {
                        this.updateObjectField('login', 'error', `Innloggingen feilet! Mangler info i noen av feltene under.`);
                    } else {
                        this.updateObjectField('login', 'error', `Login failed! Missing information.`);
                    }
                    break;
                default:
                    this.updateObjectField('login', 'errorIcon', 'fas fa-bomb');
                    if (language === 'no') {
                        this.updateObjectField('login', 'error', `Innloggingen feilet! ${loginResponse.status}: ${loginResponse.message}`);
                    } else {
                        this.updateObjectField('login', 'error', `Login failed! ${loginResponse.status}: ${loginResponse.message}`);
                    }
                    break;
            }
        }
    }

    async validateLoginCodePhone(cellphone, code, language = 'en') {
        const isValidCode = util.validateCode(code, 1000, 999999);
        if (isValidCode) {
            const loginResponse = await util.fetchApi('/api/users/login/code/phone', { publish: true, method: 'POST' }, { cellphone, code });
            switch (loginResponse.status) {
                case 200:
                    this.localUpdateField('user', loginResponse.data.user);
                    this.updateObjectField('loginLink', 'emailSent', false);
                    util.setJwtToken(loginResponse.data.apiToken);
                    util.setUserEmail(loginResponse.data.user.email || loginResponse.data.user.cellphone);
                    PubSub.publish(topics.JWT_TOKEN_CHANGED, loginResponse.data.apiToken);
                    return true;
                    break;
                case 403:
                    if (loginResponse.message === 'Wrong code!') {
                        this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                        if (language === 'no') {
                            this.updateObjectField('login', 'error', `Feil code! Husk at du må bruke den nyeste e-posten med koder.`);
                        } else {
                            this.updateObjectField('login', 'error', `Wrong code! Remember to use the latest email.`);
                        }
                    } else if (loginResponse.message === 'Login code has expired!') {
                        this.updateObjectField('loginLink', 'emailSent', false);
                        this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                        if (language === 'no') {
                            this.updateObjectField('login', 'error', `Koden har gått ut på dato. Be om en ny og forsøk igjen.`);
                        } else {
                            this.updateObjectField('login', 'error', `Code expired. Try to get a new code and try again.`);
                        }
                    } else {
                        this.updateObjectField('login', 'errorIcon', 'fas fa-bomb');
                        if (language === 'no') {
                            this.updateObjectField('login', 'error', `Det er noe feil med koden din. Du kan forsøke å be om en ny og prøve igjen.`);
                        } else {
                            this.updateObjectField('login', 'error', `Something wrong with your code! Try to get a new code and try again.`);
                        }
                    }
                    return false;
                    break;
                case 422:
                    this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                    if (language === 'no') {
                        this.updateObjectField('login', 'error', `Innloggingen feilet! Mangler info i noen av feltene under.`);
                    } else {
                        this.updateObjectField('login', 'error', `Login failed! Missing information.`);
                    }
                    return false;
                    break;
                default:
                    this.updateObjectField('login', 'errorIcon', 'fas fa-bomb');
                    if (language === 'no') {
                        this.updateObjectField('login', 'error', `Innloggingen feilet! ${loginResponse.status}: ${loginResponse.message}`);
                    } else {
                        this.updateObjectField('login', 'error', `Login failed! ${loginResponse.status}: ${loginResponse.message}`);
                    }
                    return false;
                    break;
            }
        }
    }

    async validateLoginToken(loginToken, language = 'en') {
        const isValidToken = util.isString(loginToken);
        if (isValidToken) {
            const loginResponse = await util.fetchApi('/api/users/login/token', { publish: true, method: 'POST' }, { loginToken });
            switch (loginResponse.status) {
                case 200:
                    this.localUpdateField('user', loginResponse.data.user);
                    this.updateObjectField('loginLink', 'emailSent', false);
                    util.setJwtToken(loginResponse.data.apiToken);
                    util.setUserEmail(loginResponse.data.user.email);
                    PubSub.publish(topics.JWT_TOKEN_CHANGED, loginResponse.data.apiToken);
                    return loginResponse;
                case 403:
                    if (loginResponse.message === 'Wrong login token!') {
                        this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                        if (language === 'no') {
                            this.updateObjectField('login', 'error', `Feil token! Husk at du må bruke den nyeste e-posten med koder.`);
                        } else {
                            this.updateObjectField('login', 'error', `Wrong token! Remember to use the latest email.`);
                        }
                    } else if (loginResponse.message === 'Login token has expired!') {
                        this.updateObjectField('loginLink', 'emailSent', false);
                        this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                        if (language === 'no') {
                            this.updateObjectField('login', 'error', `Token har gått ut på dato. Be om en ny og forsøk igjen.`);
                        } else {
                            this.updateObjectField('login', 'error', `Token expired. Try to get a new code and try again.`);
                        }
                    } else {
                        this.updateObjectField('login', 'errorIcon', 'fas fa-bomb');
                        if (language === 'no') {
                            this.updateObjectField('login', 'error', `Det er noe feil med koden din. Du kan forsøke å be om en ny og prøve igjen.`);
                        } else {
                            this.updateObjectField('login', 'error', `Something wrong with your token! Try to get a new code and try again.`);
                        }
                    }
                    break;
                case 422:
                    this.updateObjectField('login', 'errorIcon', 'fas fa-exclamation-triangle');
                    if (language === 'no') {
                        this.updateObjectField('login', 'error', `Innloggingen feilet! Mangler info i noen av feltene under.`);
                    } else {
                        this.updateObjectField('login', 'error', `Login failed! Missing information.`);
                    }
                    break;
                default:
                    this.updateObjectField('login', 'errorIcon', 'fas fa-bomb');
                    if (language === 'no') {
                        this.updateObjectField('login', 'error', `Innloggingen feilet! ${loginResponse.status}: ${loginResponse.message}`);
                    } else {
                        this.updateObjectField('login', 'error', `Login failed! ${loginResponse.status}: ${loginResponse.message}`);
                    }
                    break;
            }
        }
    }

    @action
    removeFieldLocal({ field, email, value }) {
        if (util.isArray(this.user[field])) {
            const idx = this.user[field]?.findIndex(e => e === value);
            if (idx > -1) {
                this.user[field].splice(idx, 1);
            }
        }
        const widx = this.users?.findIndex(e => e.email === email);
        if (widx > -1) {
            const idx = this.users[widx][field]?.findIndex(e => e === value);
            if (idx > -1) {
                this.users[widx][field].splice(idx, 1);
            }
        }
    }

    /*
        removeHideTeamSuggestions = async (e) => {
            e.preventDefault();
            e.stopPropagation();
            const { value } = e.target.closest('i').dataset;
            const { userStore } = this.props.stores;
            const { user } = userStore;
            await userStore.removeField({ email: user.email, field: 'hideTeamSuggestions', value: parseInt(value, 10) });
        }
    */
    async removeField({ field, email, value }) {
        const apiFieldName = `remove${util.ucfirst(field)}`;
        const response = await util.fetchApi(`/api/users/`, { publish: true, method: 'PATCH' }, { [apiFieldName]: value });
        switch (response.status) {
            case 200:
                this.removeFieldLocal({ field, email, value });
                return response;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    @action
    updateUserArrayLocal({ field, email, value }) {
        if (!util.isArray(this.user[field])) {
            this.user[field] = [];
        }
        this.user[field].push(value);

        const widx = this.users?.findIndex(e => e.email === email);
        if (widx > -1) {
            if (!util.isArray(this.users[widx][field])) {
                this.users[widx][field] = [];
            }
            this.users[widx][field].push(value);
        }
    }

    /*
        hideTeamSuggestions = async (e) => {
            e.preventDefault();
            e.stopPropagation();
            const { value } = e.target.closest('i').dataset;
            const { userStore } = this.props.stores;
            const { user } = userStore;
            await userStore.updateUserArray({ email: user.email, field: 'hideTeamSuggestions', value: parseInt(value, 10) });
        }
    */
    async updateUserArray({ email, field, value }) {
        const response = await util.fetchApi(`/api/users/`, { publish: true, method: 'PATCH' }, { [field]: value });
        switch (response.status) {
            case 200:
                this.updateUserArrayLocal({ field, email, value });
                break;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    @action
    findApplicationById(appId) {
        if (util.isArray(this.user.applications)) {
            const idx = this.user.applications?.findIndex(e => e.appId === appId);
            if (idx > -1) {
                return this.user.applications[idx];
            }
        }
        return null;
    }

    async getSimulateUser(email) {
        const simulateResponse = await util.fetchApi(`/api/users/simulate/${email}`, { publish: true, method: 'GET' }, {});
        switch (simulateResponse.status) {
            case 200:
                this.localUpdateField('user', simulateResponse.data.user);
                util.setJwtToken(simulateResponse.data.apiToken);
                util.setUserEmail(simulateResponse.data.user.email);
                await this.getInfo();
                PubSub.publish(topics.JWT_TOKEN_CHANGED, simulateResponse.data.apiToken);
                route('/');
                break;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async setLanguage(language = 'en') {
        const { id } = this.user;
        const response = await util.fetchApi(`/api/users/${id}`, { publish: true, method: 'PATCH' }, { language });
        switch (response.status) {
            case 200:
                this.updateObjectField('user', 'language', language);
                await this.getInfo();
                window.location.reload(true);
                break;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async setLastUpdated({ field, value }) {
        const { email } = this.user;
        const response = await util.fetchApi(`/api/users/`, { publish: true, method: 'PATCH' }, { [field]: value });
        switch (response.status) {
            case 200:
                this.updateUserSubValue(field, value);
                break;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async setToggleFeature({ field, value }) {
        const { email } = this.user;
        const response = await util.fetchApi(`/api/users/`, { publish: true, method: 'PATCH' }, { [field]: value });
        switch (response.status) {
            case 200:
                this.updateUserSubValue(field, value);
                break;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async updatePlatform() {
        const { platformId, platformVersion } = window.cordova || {};
        // console.log({ platformId, platformVersion });
        const { email } = this.user;
        const response = await util.fetchApi(`/api/users/`, { publish: true, method: 'PATCH' }, { platformId, platformVersion });
        switch (response.status) {
            case 200:
                this.updateUserField('platformId', platformId);
                this.updateUserField('platformVersion', platformVersion);
                break;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async saveDeviceInfo(deviceInfo) {
        const { email } = this.user;
        const response = await util.fetchApi(`/api/users/`, { publish: true, method: 'PATCH' }, { deviceInfo });
        switch (response.status) {
            case 200:
                this.updateUserField('deviceInfo', deviceInfo);
                break;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    @action
    updateSettingLocal(settings) {
        const userSettings = {
            ...this.user.settings,
            ...settings,
        };
        this.user.settings = userSettings;
    }

    async updateSetting(settings, settingsRaw) {
        const response = await util.fetchApi(`/api/users/${this.user.email}`, { publish: true, method: 'PATCH' }, { ...settings });
        switch (response.status) {
            case 200:
                this.updateSettingLocal(settingsRaw);
                return response;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async updateUser(email, object = {}) {
        const response = await util.fetchApi(`/api/users/`, { publish: true, method: 'PATCH' }, { ...object });
        switch (response.status) {
            case 200:
                // Loop through object and update user
                Object.keys(object).forEach((key) => {
                    this.updateUserField(key, object[key]);
                });
                return true;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async changePassword({ password, passwordConfirm }) {
        const response = await util.fetchApi(`/api/users/`, { publish: true, method: 'PATCH' }, {
            password,
            passwordConfirm,
        });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async searchUsers({ search, showAll }) {
        const response = await util.fetchApi(`/api/users/search`, { publish: true, method: 'GET' }, { search, showAll });
        switch (response.status) {
            case 200:
                this.localUpdateField('searchUsersResult', response.data);
                return response;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async searchUsersAll({ search }) {
        const response = await util.fetchApi(`/api/users/search-all`, { publish: true, method: 'GET' }, { search });
        switch (response.status) {
            case 200:
                this.localUpdateField('searchUsersResult', response.data);
                return response;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async getWeather({ lat, lon, altitude, force, workout }) {
        if (!lat || !lon) {
            return [];
        }
        const response = await util.fetchApi(`/api/yr/`, { publish: true, method: 'GET' }, { lat, lon, altitude, force, workout });
        switch (response.status) {
            case 200:
                return response.data;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async loadFavorites({ query, limit, offset, search, opts = {} }) {
        const response = await util.fetchApi(`/api/users/favorites/`, { publish: true, method: 'GET' }, { ...query, search, limit, offset, ...opts });
        switch (response.status) {
            case 200:
                if (offset > 0) {
                    let favorites = toJS(this.favorites);
                    favorites.push(...response.data);
                    this.localUpdateField('favorites', favorites);
                } else {
                    this.localUpdateField('favorites', response.data);
                }
                return response;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async simulateCellphone(cellphone = '') {
        const simulateResponse = await util.fetchApi(`/api/users/simulate/${cellphone}`, { publish: true, method: 'GET' }, {});
        switch (simulateResponse.status) {
            case 200:
                this.localUpdateField('user', simulateResponse.data.user);
                util.setJwtToken(simulateResponse.data.apiToken);
                util.setUserCellphone(simulateResponse.data.user.cellphone);
                await this.getInfo();
                PubSub.publish(topics.JWT_TOKEN_CHANGED, simulateResponse.data.apiToken);
                // route('/');
                alert(`Simulating cellphone: ${cellphone}`);
                break;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

}

const store = new UserStore();
export default store;
