import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import localUtil from '../../lib/util';

const MARGIN_TOP = localUtil.marginTop(false);
const MARGIN_BOTTOM = localUtil.marginBottom();
const SCROLLDOWN_LIMIT = 500;
const DRAWER_Z_INDEX = 1000000;

@withText(props => ({
    back: <Text id='header.back'>Back</Text>,
}))
@observer
class DrawerRight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            xPos: 100,
        };
    }

    onCloseClick = (e) => {
        const { onCloseClick, drawer, callback = () => {} } = this.props;
        if (onCloseClick) {
            onCloseClick();
            callback();
        } else {
            this.animateXPos('close');
            setTimeout(() => {
                const { appState } = this.props.stores;
                appState.toggleDrawerRight(false, drawer);
                callback();
            }, 300);
        }
    }

    onScroll = (e) => {
        const { storyStore, teamStore, workoutStore } = this.props.stores;
        const { drawerComponent } = this.props;
        // Scroll to top function
        const element = e.target;
        const { scrolledDown } = this.state;
        if (element) {
            if (element.scrollTop > SCROLLDOWN_LIMIT && !scrolledDown) {
            // if (element.scrollTop > SCROLLDOWN_LIMIT) {
                this.setState({
                    scrolledDown: element.scrollTop,
                });
            } else if (element.scrollTop === 0) {
                this.setState({
                    scrolledDown: 0,
                });
            }
        }

        // Load more content function
        const { isAtBottom } = this.state;
        const marginToBottom = element.clientHeight;
        if (element.scrollTop + element.clientHeight + marginToBottom >= element.scrollHeight) {
            if (!isAtBottom) {
                this.setState({ isAtBottom: true });
                // console.log(`onScroll.isAtBottom.loadMore: ${drawerComponent}`);
                switch (drawerComponent) {
                   case 'publicTeam':
                       return teamStore.loadMore();
                   case 'publicStoryList':
                       return storyStore.loadMore();
                   case 'teamsFollowing':
                       return teamStore.loadMore();
                   case 'teamsFollowers':
                       return teamStore.loadMore();
                }
            }
        } else if (isAtBottom) {
            this.setState({ isAtBottom: false });
        }
    }

    animateXPos = (direction = 'open') => {
        setTimeout(() => {
            if (direction === 'open') {
                this.doAnimateXPosOpen();
            } else {
                this.doAnimateXPosClose();
            }
        }, 1);
    }

    doAnimateXPosOpen = () => {
        const { xPos } = this.state;
        const newXpos = xPos - 10;

        this.setState({
            xPos: newXpos,
        }, () => {
            if (newXpos > 0) {
                this.animateXPos('open');
            }
        });
    }

    doAnimateXPosClose = () => {
        const { xPos } = this.state;
        const newXpos = xPos + 10;

        this.setState({
            xPos: newXpos,
        }, () => {
            if (newXpos < 100) {
                this.animateXPos('close');
            }
        });
    }

    componentDidMount() {
        this.animateXPos('open');
	}

    render() {
        const { xPos } = this.state;
        const { appState, userStore } = this.props.stores;
        const { currentCustomer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

        const { back } = this.props;

        const {
            children,
            classNames = 'fixed-top',
            width = '100vw',
            height = '100vh',
            // height = '75vh',
            editFunction,
            drawer = 1,
            borderRadius = '20px 20px 0 0 !important',
            title,
            showCloseButton = false,
            showBackButton = true,
            backButtonText = back,
            rightButtonText,
            rightButtonClick = () => {},
            id,
        } = this.props;

        let isAndroid = false;
		if (/Android/i.test(navigator.userAgent)) {
			isAndroid = true;
        }

        return (<>
            {/* <div
                class='w-100 h-100 fixed-top'
                style={`
                    height: calc(100vh) !important;
                    top: 0;
                    left: 0;
                    overflow-x: auto;
                    overflow-y: auto;
                    transition: .3s;
                    overscroll-behavior: contain;
                    z-index: ${DRAWER_Z_INDEX + drawer * 10};
                    background-color: rgba(0,0,0,.6);
                `}
                onClick={this.onCloseClick}
                onTouchmove={localUtil.captureEvents}
                onScroll={localUtil.captureEvents}
                onWheel={localUtil.captureEvents}
            /> */}

            <div
                class={`${classNames} ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} bg-light ${title ? 'pt-5' : ''}`}
                style={`
                    top: ${isAndroid ? 30 : 0}px;
                    right: 0;
                    margin-left: ${xPos}vw;
                    width: ${width};
                    height: ${height};
                    transition: .3s;
                    overflow: hidden;
                    z-index: ${DRAWER_Z_INDEX + drawer * 10 + 1};
                    ${currentCustomer?.backgroundColor ? `background-color: ${currentCustomer.backgroundColor} !important;` : ''}
                `}
                onTouchmove={localUtil.captureEvents}
                onScroll={localUtil.captureEvents}
                onWheel={localUtil.captureEvents}
            >
                {title && <div class='position-absolute w-100 bg-light pt-2 pb-1'
                    style={`
                        top: 0px;
                        left: 0px;
                        z-index: ${DRAWER_Z_INDEX + drawer * 10 + 3};
                    `}
                >
                    <h4 class='text-center'>{title}</h4>
                </div>}

                {showBackButton && <button
                    type='button'
                    class={`btn btn-link position-absolute appHeader ${linkTextClass}`}
                    style={`
                        top: 4px;
                        left: 5px;
                        z-index: ${DRAWER_Z_INDEX + drawer * 10 + 3};
                        border-radius: 50%;
                        ${linkTextStyle}
                    `}
                    onClick={this.onCloseClick}
                >
                    <i class={`fa-regular fa-arrow-left`} /> {backButtonText}
                </button>}

                {editFunction && <div
                    class='position-absolute'
                    style={`
                        top: 10px;
                        right: 20px;
                        z-index: ${DRAWER_Z_INDEX + drawer * 10 + 3};
                    `}
                >
                    <button
                        type='button'
                        // class='btn btn-link position-absolute appHeader text-secondary'
                        class='btn btn-sm btn-outline-secondary rounded-pill text-secondary'

                        data-id={id}
                        data-title={title}
                        onClick={editFunction}
                    >
                        <i class='fa-regular fa-pen' /> <Text id='input.edit'>Edit</Text>
                    </button>
                </div>}
                {rightButtonText && <button
                    type='button'
                    class='btn btn-link position-absolute appHeader text-primary'
                    style={`
                        top: 4px;
                        right: 4px;
                        z-index: ${DRAWER_Z_INDEX + drawer * 10 + 3};
                    `}
                    onClick={rightButtonClick}
                >
                    {rightButtonText}
                </button>}
                {showCloseButton && <button
                    type='button'
                    class='btn btn-sm btn-secondary float-right position-absolute'
                    style={`
                        top: 16px;
                        right: 16px;
                        width: 35px;
                        height: 35px;
                        z-index: ${DRAWER_Z_INDEX + drawer * 10 + 3};
                        border-radius: 50%;
                        filter: opacity(0.5);

                    `}
                    onClick={this.onCloseClick}
                >
                    <i class='fas fa-times' />
                </button>}
                <div
                    class={`h-100 overflow-auto bg-light`}
                    // class={`h-100 position-relative overflow-auto bg-light`}
                    onScroll={this.onScroll}
                    style={`
                        ${currentCustomer?.backgroundColor ? `background-color: ${currentCustomer.backgroundColor} !important;` : ''}
                    `}
                >
                    {children}
                    {isAdmin && <>
                        <div class='fixed-bottom bg-info text-center w-25' style='bottom: 0; right: 0; line-height: 0.9em; font-size: 0.7em;'>
                            drawer: {drawer}
                        </div>
                    </>}
                </div>
            </div>
        </>);
    }
}

export default DrawerRight;
