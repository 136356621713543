module.exports = {
    version: '1.0.0',
    debug: true,
    logLevel: 'debug', // debug, verbose, info
    name: 'Soknadsguiden', // Name of the app
    emailSender: 'post@soknadsguiden.app',
    development: {
        email: 'sorenso@gmail.com',
    },
    websocket: {
        port: 1333,
    },
    app: {
        port: 8888,
        logFile: '/tmp/access.log',
        protocol: 'http',
        domain: '0.0.0.0:8181',
    },
    jwt: {
        secret: 'This IS the JwT secret for use when signing tokens in your app! :)',
        secretSimpleBlog: 'This is the JWT secret',
        expiry: {
            loginToken: '2y',
            fingerprintToken: '1d',
        },
    },
    urls: {
        prod: {
            imageS3Bucket: 'soknadsguiden-images-original',
            imageS3BucketResized: 'soknadsguiden-images-resized',
            logFileS3Bucket: 'soknadsguiden-logs',
            imageServer: 'https://soknadsguiden.app',
            fileServer: 'https://soknadsguiden.app/original',
            logServer: 'https://soknadsguiden.app/logs',
            screenshotServer: 'https://test.soknadsguiden.app',

            videoS3Bucket: 'web-video-original',
            videoS3BucketStream: 'web-video-resized',
            videoServer: 'https://soknadsguiden.app',
        },
        dev: {
            imageS3Bucket: 'soknadsguiden-images-original',
            imageS3BucketResized: 'soknadsguiden-images-resized',
            logFileS3Bucket: 'soknadsguiden-logs',
            imageServer: 'https://soknadsguiden.app',
            fileServer: 'https://soknadsguiden.app/original',
            logServer: 'https://soknadsguiden.app/logs',
            screenshotServer: 'https://test.soknadsguiden.app',

            videoS3Bucket: 'web-video-original',
            videoS3BucketStream: 'web-video-resized',
            videoServer: 'https://soknadsguiden.app',
        },
    },
    links: {
        prod: {
            login: 'https://soknadsguiden.app/#/link/login/',
            verify: 'https://soknadsguiden.app/#/link/verify/',
            unlock: 'https://soknadsguiden.app/#/link/unlock/',
        },
        dev: {
            login: 'http://localhost:8080/#/link/login/',
            verify: 'http://localhost:8080/#/link/verify/',
            unlock: 'http://localhost:8080/#/link/unlock/',
        },
    },
    texts: {
        registerSubject: 'Welcome!',
        register: `Hi,

        Thank you so much for using signing up to The Soknadsguiden app.

        I hope you will be satisfied with the app and welcome your feedback anytime.

        Confirm you email by clicking this link:
        <a href="{{serverUrl}}{{verifyEmailToken}}">Confirm email: {{email}}</a>

        Best regards,
        Soknadsguiden`,

        loginLinkSubject: 'Magical loginlink to The Soknadsguiden app!',
        loginLink: `Hi,

        You have requested to reset you password.

        <h5>Please use this link to login:</>
        <a href="{{serverUrl}}{{loginToken}}">Login to The Soknadsguiden app</a>

        <strong>Or</strong> you can use this login code. It's only valid for 10 minutes:
        <h1 style='letter-spacing: 5px;'>{{loginCode}}</h1>

        If you haven't requested to reset you password, just delete this email and forget about it.

        Best regards,
        Soknadsguiden`,

        loginTokenSubject: 'Login token to The Soknadsguiden app!',
        loginToken: `Hi,

        You have requested a login token to Soknadsguiden.

        <strong>Login code</strong>. It's only valid for 10 minutes:
        <h1 style='letter-spacing: 5px;'>{{loginCode}}</h1>

        If you haven't requested this code, just delete this email and forget about it.

        Best regards,
        Soknadsguiden`,

        resendVerifySubject: 'Verify you email at Soknadsguiden',
        resendVerify: `Hi,

        You have requested a new email verification link. If this is not done by you no action
        is needed. Just delete this email and forget all about it.

        Please confirm your email address by clicking this link:
        <a href="{{serverUrl}}{{verifyEmailToken}}">Confirm my email address</a>

        Best regards,
        Soknadsguiden`,

        unlockSubject: 'Unlock you account at Soknadsguiden!',
        unlock: `Hi,

        You have requested an unlock link for you account. If this is not done by
        you no action is needed. Just delete this email and forget all about it.

        Please unlock your account by clicking this link:
        <a href="{{serverUrl}}{{verifyEmailToken}}">Unlock my account</a>

        Best regards,
        Soknadsguiden`,
        passwordChangeSubject: 'Your password has been changed at Soknadsguiden!',
        passwordChange: `Hi,

        Your password has been changed at Soknadsguiden.

        Timestamp: {{timestamp}}
        IP address: {{ip}}
        Useragent: {{useragent}}

        Best regards,
        Soknadsguiden`,
    },
    server: {
        protocol: 'http',
        domain: 'localhost',
        topDomain: 'localhost',
        port: 8888,
        logFile: '/tmp/access.log'
    },
    useDataDog: true,
    mongo: {
        dbName: 'soknadsguiden',
        url: 'mongodb://localhost:27017/soknadsguiden?safe=true&auto_reconnect=true&poolSize=20&reconnectTries=20&reconnectInterval=5000',
    },
    awsApi: {
        accessKeyId: 'AKIATBP2QGNXFVOUPYF5',
        secretAccessKey: '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',
        region: 'eu-west-1',
    },
};
