import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class NewApplicationOffer extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('applicationOffer'),
        };
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id } = props;
        const { applicationOfferStore } = this.props.stores;
        if (emptyFirst) {
            applicationOfferStore.updateKeyValue('applicationOffer', {});
        }
        await applicationOfferStore.load(id, false, { addData: ['tags'], query: {} });
    }

    createApplicationOffer = async () => {
        const { callback = () => {} } = this.props;
        const { applicationOfferStore } = this.props.stores;
        const { newApplicationOffer } = applicationOfferStore;

        const response = await applicationOfferStore.insert(newApplicationOffer);

        applicationOfferStore.updateKeyValue('newApplicationOffer', {});
        callback();
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { week, isNew, drawerLevel } = this.props;
        const { sections } = this.state;
        const { userStore, applicationOfferStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { newApplicationOffer } = applicationOfferStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {isNew ? <Text id='applicationOffer.new'>New applicationOffer</Text> : <Text id='applicationOffer.edit'>Edit applicationOffer</Text>}
                    </div>
                    <div class='w-100 d-flex flex-column justify-content-start'>
                        <div class='bg-light d-flex flex-column justify-content-start h-100 pt-4'>
                            <FormFields
                                stores={this.props.stores}
                                sections={sections}
                                holdingStore={applicationOfferStore}
                                objectName={isNew ? 'newApplicationOffer' : 'applicationOffer'}
                                isNew={isNew}
                                drawerLevel={drawerLevel}
                                isAdmin={false}
                            />
                        </div>
                    </div>
                    {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.createApplicationOffer}>
                            {isNew ? <Text id='applicationOffer.create'>Create applicationOffer</Text> : <Text id='applicationOffer.save'>Save applicationOffer</Text>}
                        </button>
                    </div>}
                </div>
            </div>
        </>);
    }
}

export default NewApplicationOffer;
