import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class CustomerStore extends LocalModel {
    constructor() {
        super('customer', {
            namePlural: 'customers',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/customers/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/customers/',
                    params: {},
                },
                save: {
                    url: '/api/customers/',
                    params: {},
                },
                delete: {
                    url: '/api/customers/',
                    params: {},
                },
            },
        });
    }

    @observable newCustomer = {};

    @observable customer = {};

    @observable customerPublic = {};

    @observable customers = [];

    @observable members = [];

    async loadPublic(id) {
        const response = await util.fetchApi(`/api/customers/public/${id}`, { method: 'GET' }, {});
        if (response?.status === 200) {
            this.updateKeyValue('customerPublic', response.data);
        }
    }

}

const store = new CustomerStore();
export default store;
