import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import ImageScroller from '../../components/imagescroller';
import VideoStreamingPlayer from '../../routes/videostreaming/player';

import NewsList from './list';

import localUtil from '../../lib/util';

function partImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3LargeLink}`;
    return imgSrc;
}

@observer
class NewsView extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { id } = props;
        const { newsStore } = this.props.stores;
        if (id) {
            newsStore.updateKeyValue('news', {});
            await newsStore.load(id, false, { addData: ['tags'], query: { all: 1 } });
        }
    }

    viewTags = obj => {
        if (obj.tags?.length === 0 && obj.loans?.length === 0 && !obj.ownedByOther && !obj.url && !obj.parents?.length > 0 && !obj.files?.length > 0) {
            return '';
        }
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tagClass = darkmode ? 'dark' : 'light';

        return (<>
            <div class='w-100 overflow-hidden'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {obj.files?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-file' /> <span class='font-weight-lighter'> x {obj.files.length}</span>
                        </span>
                    </>}
                    {obj.tags?.length > 0 && obj.tags.map(tag => {
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    editNews = () => {
		const { id, drawerLevel } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('newsEdit', {
			height: drawerHeightMedium,
			id,
		}, drawerLevel + 1);
	}

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            page,
            artid,
            bib,
            showAnimations = true,
            tag = 'frontpage',
            drawerLevel,
        } = this.props;
        const { sessionid, showMenu } = this.state;
        const { appState, userStore, newsStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { news } = newsStore;

        const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    {isAdmin && <div class={`position-absolute`} style={`top: 20px; right: 50px; z-index: 100000;`}>
                        <button onClick={this.editNews} class={`btn btn-sm btn-outline-secondary rounded-pill mr-2`}>
                            <i class={`fa-regular fa-pen mr-2`} />
                            <Text id='news.edit'>Edit news</Text>
                        </button>
                    </div>}


                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class='d-flex flex-column'>
                                {/* {JSON.stringify(part)} */}
                                {news.files?.length > 0 && news.files.map(file => {
                                    const poster = file.s3LinkStreamPreview1;
                                    // console.log({ file });
                                    if (file.s3LinkStream) {
                                        return (<>
                                            <div class='video-container rounded-lg'>
                                                <VideoStreamingPlayer
                                                    key={file.s3LinkStream}
                                                    videoStreamUrl={file.s3LinkStream}
                                                    stores={this.props.stores}
                                                    poster={poster}
                                                />
                                            </div>
                                        </>);
                                    }
                                    if (file.s3Link.match(/\.(m4v|m4a|mp4)$/i)) {
                                        return (<>
                                            <div className='video-container rounded-lg'>
                                                <video controls className='video-player'>
                                                    <source src={file.s3Link} type='video/mp4' />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        </>);
                                    }
                                    if (file.s3Link.match(/\.(mov)$/i)) {
                                        return (<>
                                            <div className='video-container rounded-lg'>
                                                <video controls className='video-player'>
                                                    <source src={file.s3Link} type='video/quicktime' />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                            <div class='video-info d-flex flex-row justify-content-center'>
                                                <span class='text-muted'>size:</span> {localUtil.formatFileSize(file.size)}
                                                <span class='text-muted ml-3 mr-1'>encoding:</span> {file.encoding}
                                                <span class='text-muted ml-3 mr-1'>mimetype:</span> {file.mimetype}
                                                <span class='text-muted ml-3 mr-1'>ext:</span> {file.ext}
                                                <span class='text-muted ml-3 mr-1'>s3Link:</span> <a href={file.s3Link} target='_blank' rel='noopener noreferrer'>download</a>
                                            </div>
                                        </>);
                                    }
                                })}
                                <ImageScroller images={news.images} stores={this.props.stores} showImg={img => partImg(img, this.props, '1920x')} />
                            </div>
                            <div class={`d-flex flex-column mx-3 mt-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                <div>
                                    <h5>{news.title}</h5>
                                </div>
                                <div class='font-weight-lighter'>
                                    {util.formatDate(news.date, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                </div>
                                <div class='d-flex flex-column mb-3 font-weight-bold'>
                                    <Markdown markdown={localUtil.replaceImages(news.ingress, news.images, language, false)} markedOpts={localUtil.getMarkdownOptions()} />
                                </div>
                                <div class='d-flex flex-column'>
                                    <Markdown markdown={localUtil.replaceImages(news.body, news.images, language, false)} markedOpts={localUtil.getMarkdownOptions()} />
                                </div>
                            </div>

                            {news.files && news.files.length > 0 && <>
                                <div class={`d-flex flex-column mx-3 mt-4 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                    <div class='d-flex flex-column'>
                                        {news.files.map(file => {
                                            return (<>
                                                <a href={file.s3Link} class='badge badge-pill badge-light border mr-2' target='_blank' rel='noopener noreferrer'>{file.name} <span class='font-weight-lighter'>({localUtil.formatFileSize(file.size)})</span></a>
                                            </>);
                                        })}
                                    </div>
                                </div>
                            </>}

                            {news.tags && news.tags.length > 0 && <div class={`d-flex flex-column mx-3 mt-4 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                {this.viewTags(news)}
                            </div>}
                        </div>
                    </div>

                    {/* <SimpleBlog stores={this.props.stores} {...this.props} /> */}
                    <NewsList
                        classNames={'mt-5'}
                        key={`more-news-list`}
                        keyName={`more-news-list`}
                        stores={this.props.stores}
                        hideEmpty={true}
                        limit={10}
                        tag={tag}
                        skipUpdate={true}
                        showHeader={true}
                        title={<Text id='news.list-more'>More news</Text>}
                        drawerLevel={drawerLevel - 1}
                    />

                </div>
            </div>
        </>);
    }
}

export default NewsView;
