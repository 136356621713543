import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Input from '../../components/form/input';

function isValidCellphone(input) {
    // Define a regular expression pattern for a valid cellphone number
    const pattern = /\d{8}$/; // Assumes a 8-digit number

    // Test the input against the pattern
    return pattern.test(input);
}

const initialState = {};

@observer
class SendSms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            subject: '',
            body: '',
            textTemplateSaved: {},
            textTemplates: [],
        };
    }

    loadAll = async () => {
        const { textTemplateStore, appState } = this.props.stores;
        const tpls = await textTemplateStore.load({ query: { section: 'sendSms' }, skipUpdate: 1 });
        this.setState({ textTemplates: tpls });

        const { message } = this.props;
        if (message) {
            appState.updateObjectKeyValue('newSms', 'body', message);
        }
    }

    sendSms = async (e) => {
        this.setState({
            inProgress: true,
        });
        const { appState, userStore } = this.props.stores;
        const { customer } = userStore;
        const { smsSender = 'soknadsguiden' } = customer;
        const { drawerLevel = 1, users = [], from = smsSender } = this.props;
        const { newSms = {} } = appState;

        if (!newSms.body) {
            this.setState({
                errorTmp: 'Error! Please fill in all fields.',
                inProgress: false,
            });
            return;
        }

        const response = await appState.sendSms({
            // from: '',
            from,
            to: users.filter(u => isValidCellphone(u.cellphone)).map(u => u.cellphone),
            body: newSms.body,
        })

        if (response.status === 200) {
            appState.updateKeyValue('newSms', {});
            this.setState({
                message: 'Sms sent!',
                inProgress: false,
            });
            setTimeout(() => {
                appState.toggleDrawer();
            }, 1000);
        } else {
            this.setState({
                error: 'Error! Could not send sms. Please try again later.',
                inProgress: false,
            });
        }
    }

    saveTextTemplate = async (e) => {
        const { textTemplateStore, appState } = this.props.stores;
        const { section, field } = e.target.closest('button').dataset;
        const { newSms = {} } = appState;
        const response = await textTemplateStore.insert({
            section,
            field,
            body: newSms[field],
        });
        if (response.status === 201) {
            const { textTemplateSaved } = this.state;
            textTemplateSaved[`${field}`] = true;
            this.setState({ textTemplateSaved });
            setTimeout(() => {
                const { textTemplateSaved } = this.state;
                delete textTemplateSaved[`${field}`];
                this.setState({ textTemplateSaved });
            }, 3000);
        }
    }

    handleSelectTextTemplate = async (e) => {
        const { textTemplates } = this.state;
        // const { textTemplates } = textTemplateStore;
        const { appState } = this.props.stores;
        const { section, field } = e.target.closest('select').dataset;
        const { value } = e.target;
        const textObject = textTemplates.find(t => t.id === parseInt(value, 10));
        const { newSms = {} } = appState;
        const currentObject = newSms;

        const currentValue = currentObject[field] || '';
        // console.log('handleSelectTextTemplate', { newSms, field, value, textObject, currentObject, currentValue });
        appState.updateObjectKeyValue('newSms', field, `${currentValue}${currentValue ? '\n\n' : ''}${textObject.body}`);
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const {
            error,
            errorTmp,
            message,
            inProgress,
            textTemplateSaved,
            textTemplates,
        } = this.state;
        const { from, users = [] } = this.props;
        const { appState } = this.props.stores;
        const { newSms = {} } = appState;

        const textTpls = textTemplates.filter(t => t.section === 'sendSms' && t.field === 'body');

        return (<>
            <div class='d-flex flex-column justify-content-start overflow-auto mb-5 mt-3 p-4'>
                <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                    <Text id='input.admin.send-sms-title'>Send Sms</Text>
                </div>

                {/* <xmp>{JSON.stringify(users, null, 4)}</xmp> */}
                <div class='d-flex flex-row justify-content-start px-2'>
                    {from && <>
                        <span class='mr-2 text-muted'>
                            <Text id='input.from'>From</Text>
                        </span>
                        <span class={`badge badge-pill badge-secondary font-weight-normal mr-1 mt-1`}>
                            {from}
                        </span>
                    </>}
                </div>

                <div class='d-flex flex-wrap justify-content-start px-2'>
                    <span class='mr-2 text-muted'>
                        <Text id='input.to'>To</Text>
                    </span>
                    {users.filter(u => isValidCellphone(u.cellphone)).map(user => {
                        return (<>
                            <span class={`badge badge-pill badge-secondary font-weight-normal mr-1 mt-1`}>
                                {user.firstname} {user.lastname} &lt;{user.cellphone}&gt;
                            </span>
                        </>);
                    })}
                </div>
                {users.filter(u => !isValidCellphone(u.cellphone)).length > 0 && <>
                    <div class='d-flex flex-wrap justify-content-start px-2'>
                        <span class='mr-2 text-muted'>
                            <Text id='input.invalid-cellphones'>Invalid cellphones</Text>
                        </span>
                        {users.filter(u => !isValidCellphone(u.cellphone)).map(user => {
                            return (<>
                                <span class={`badge badge-pill badge-danger font-weight-normal mr-1 mt-1`}>
                                    {user.firstname} {user.lastname} &lt;{user.cellphone}&gt;
                                </span>
                            </>);
                        })}
                    </div>
                </>}

                <Localizer>
                    <Input
                        classNames='form-control rounded-lg'
                        title={<Text id='input.sms-body'>Sms content</Text>}
                        placeholder={<Text id='input.sms-body'>Sms content</Text>}
                        help={<Text id='input.sms-body-help'>Your sms content. Do NOT include links! SMS providers will stop the SMS from beeing sent.</Text>}
                        stores={this.props.stores}
                        holdingStore={appState}
                        object={newSms}
                        objectName={'newSms'}
                        type={'textarea'}
                        field={'body'}
                        value={newSms.body}
                        extraDataset={{
                            'data-isnew': true,
                        }}
                    />
                </Localizer>

                <div class='d-flex flex-row justify-content-end align-items-end'>
                    <button
                        class='btn btn-link btn-sm text-muted'
                        onClick={this.saveTextTemplate}
                        data-section={'sendSms'}
                        data-field={'body'}
                    >
                        <i class='fa-duotone fa-floppy-disk' /> <Text id='input.save-text-template'>Save as template</Text>
                    </button>
                    {textTemplateSaved.body && <>
                        <div class='alert alert-success' role='alert'>
                            <Text id='input.text-template-saved'>Text template saved</Text>
                        </div>
                    </>}
                </div>
                {textTpls && textTpls.length > 0 && <>
                    {/* section: {section.key} field: {field.name}<br />
                    <xmp>{JSON.stringify(textTpls, null, 2)}</xmp> */}

                    <div class='input-group mb-2'>
                        <div class='input-group-prepend'>
                            <div class='input-group-text'>
                                <i class='fa-duotone fa-text-size' />
                            </div>
                        </div>
                        {/* <label for='exampleFormControlSelect1'>Example select</label> */}
                        <select
                            class='form-control form-control-sm'
                            data-section={'sendSms'}
                            data-field={'body'}
                            onChange={this.handleSelectTextTemplate}
                            >
                            <option value=''>-- <Text id='input.choose-text-template'>Choose text template</Text> --</option>
                            {textTpls.map(t => {
                                return (<>
                                    <option value={t.id}>{t.body}</option>
                                </>);
                            })}
                        </select>
                    </div>
                </>}




                <div class='d-flex flex-column justify-content-center mt-5'>
                    <button
                        type='button'
                        class={`btn btn-block btn-primary rounded-pill`}
                        onClick={this.sendSms}
                        disabled={error || message}
                    >
                        <i class='fa-solid fa-paper-plane mr-1' /> Send sms
                        {inProgress && <>
                            <span class='spinner-border spinner-border-sm ml-2' role='status' aria-hidden='true'></span>
                        </>}
                    </button>

                    {error && <div class='w-100 d-flex px-3 mt-4 justify-content-center'>
                        <div class='alert alert-danger rounded-pill' role='alert'>
                            {error}
                        </div>
                    </div>}
                    {errorTmp && <div class='w-100 d-flex px-3 mt-4 justify-content-center'>
                        <div class='alert alert-danger rounded-pill' role='alert'>
                            {errorTmp}
                        </div>
                    </div>}
                    {message && <div class='w-100 d-flex px-3 mt-4 justify-content-center'>
                        <div class='alert alert-success rounded-pill' role='alert'>
                            {message}
                        </div>
                    </div>}
                </div>
            </div>
        </>);
    }
}

export default SendSms;
