import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import localUtil from '../../lib/util';

import SearchLoggedIn from './loggedIn';
import SearchNotLoggedIn from './notLoggedIn';

@observer
class Search extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { appState, userStore } = this.props.stores;
        const { user = {}, isAdmin, isTester } = userStore;

        if (user.id) {
            return (<SearchLoggedIn stores={this.props.stores} {...this.props} />);
        }
        return (<SearchNotLoggedIn stores={this.props.stores} {...this.props} />);
    }
}

export default Search;
