import { h, Component, Fragment } from 'preact';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import linkState from 'linkstate';
import util from 'preact-util';
import { route } from 'preact-router';

import BugReport from '../../components/bugReport/';
import Drawer from '../../components/drawer/';
import DrawerRight from '../../components/drawer/right';
import Settings from '../../components/settings/';
import Menu from '../../components/menu';
import SendEmail from '../../components/actions/sendEmail';
import SendSms from '../../components/actions/sendSms';

import AdminNewUser from '../../components/admin/newUser';
import AdminNewNews from '../../components/admin/newNews';
import AdminNewCustomer from '../../components/admin/newCustomer';
import AdminNewArticle from '../../components/admin/newArticle';
import AdminNewTextTemplate from '../../components/admin/newTextTemplate';
import AdminNewSms from '../../components/admin/newSms';

import AdminNewApplicationOffer from '../../components/admin/newApplicationOffer';
import AdminNewSubmittedApplication from '../../components/admin/newSubmittedApplication';

import AddArrayObject from '../../components/form/addArrayObject';
import EditArrayObject from '../../components/form/editArrayObject';
import AddArrayElement from '../../components/form/addArrayElement';

import ImageView from '../../components/imagescroller/view';
import ImageFullscreen from '../../components/gui/imageFullscreen';

import Help from '../../components/help/';

import NewsView from '../../components/news/view';
import NewsMenu from '../../components/news/menu';
import NewsEdit from '../../components/news/edit';
import NewsAdminList from '../../components/news/listAdmin';

import CustomerView from '../../components/customer/view';
import CustomerEdit from '../../components/customer/edit';
import CustomerUserEdit from '../../components/customer/editUser';

import ArticleEdit from '../../components/article/edit';
import ArticleView from '../../components/article/view';
import ArticleList from '../../components/article/list';
import ArticleAdminList from '../../components/article/listAdmin';

import ApplicationOfferView from '../../components/applicationoffer/view';
import ApplicationOfferEdit from '../../components/applicationoffer/edit';
import ApplicationOfferEditSettings from '../../components/applicationoffer/editSettings';

import SubmittedApplicationEdit from '../../components/submittedapplication/edit';
import SubmittedApplicationView from '../../components/submittedapplication/view';
import SubmittedApplicationViewAdmin from '../../components/submittedapplication/viewAdmin';

import TextTemplateEdit from '../../components/textTemplate/edit';
import TextTemplateView from '../../components/textTemplate/view';

import Search from '../../components/search';
import FileViewer from '../../components/fileViewer';
import UrlViewer from '../../components/urlViewer';

import ViewText from '../../components/gui/viewText';

import MailSentView from '../../components/mailSent/view';
import SmsView from '../../components/sms/view';

import localUtil from '../../lib/util';

const FONTSIZE_MENU = '12px;';
const FONTSIZE_ICON = '23px;';

const initialState = {
    menu: {
		profile: false,
		main: false,
		showDashoard: false,
	},
	searchText: '',
};

@observer
class MenuV2 extends Component {
	userMenu = () => {
		const { appState, userStore } = this.props.stores;
		const { drawerHeightMedium } = appState;

		const { notifications = [] } = userStore;
		const unreadNotificatins = notifications.filter(e => !e.read);
		const unread = unreadNotificatins.length || 0;
        appState.openDrawer('menu', {
            height: drawerHeightMedium,
        }, 1);
	}

	render() {
		const { pathFixed } = this.props;
		const { appState, userStore } = this.props.stores;
		const { user = {}, isAdmin, isTester } = userStore;

		const {
			path, isCordova, apiServer,
		} = appState;

		return (<>
			<div class='d-flex justify-content-between'>
				<div
					class={`text-center p-2 ${path.match(/(^\/$|\/home-wall)/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
					style='text-overflow: ellipsis; width: 20%;'
				>
					<a href={`${path === '/' ? '/home-wall' : '/'}`} class={`text-${path.match(/(^\/$|\/home-wall)/) ? 'primary' : 'secondary'}`}>
						<i class={`fa-${path.match(/(^\/$|\/home-wall)/) ? 'solid' : 'regular'} fa-house`} style={`font-size: ${FONTSIZE_ICON};`} /><br />
						<Text id='footer.home'>Home</Text>
					</a>
				</div>

				{isCordova && <>
					<div
						class={`text-center p-2 ${path.match(/\/workouts\/tracking/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
						style='text-overflow: ellipsis; width: 20%;'
					>
						<a href='/workouts/tracking/' class={`text-${path.match(/\/workouts\/tracking/) ? 'primary' : 'secondary'}`}>
							<i class={`fa-${path.match(/\/workouts\/tracking/) ? 'solid' : 'regular'} fa-circle-dot`} style={`font-size: ${FONTSIZE_ICON};`} /><br />
							<Text id='footer.workouts-start'>Start</Text>
						</a>
					</div>
				</>}

				<div
					class={`text-center p-2 ${path.match(/(^\/users\/profile)/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden d-flex justify-content-center`}
					style='text-overflow: ellipsis; width: 20%;'
				>
					<a href='/users/profile' class={`text-${path.match(/\/users\/profile/) ? 'primary' : 'secondary'}`}>
						{/* <div
							class={`position-relative text-center border rounded-circle imageRounded imageRoundedThumb2 ml-1`}
							style={image ? `background-image: url("${image}"); background-size: cover;` : ''}
						>
							{!image && <i class='fas fa-users text-muted mt-2' style='font-size: 20px;' />}
						</div> */}
						<i class={`fa-${path.match(/(^\/users\/profile)/) ? 'solid' : 'regular'} fa-user-vneck`} style={`font-size: ${FONTSIZE_ICON};`} /><br />
						<Text id='footer.me'>Me</Text>
					</a>

					{user && user.id && path && <img
						src={`${apiServer}/api/pageview/${pathFixed}/${user.id}/${activeTeam?.id}/${new Date().getTime()}`}
						style='width: 1px; height: 1px; opacity: 0.1;'
					/>}
				</div>

				<div
					class={`text-center p-2 text-nowrap overflow-hidden d-flex justify-content-center`}
					style='text-overflow: ellipsis; width: 20%;'
				>
					<div class={`text-secondary`} onClick={this.userMenu}>
						<i class='fa-solid fa-bars' style={`font-size: ${FONTSIZE_ICON};`} /><br />
						<Text id='footer.menu'>Menu</Text>
					</div>
				</div>

			</div>
			{isCordova ? <>
				{user && user.id && <img
					src={`${apiServer}/api/pageview/iscordova.true/${user.id}/${activeTeam?.id}/${new Date().getTime()}`}
					style='width: 1px; height: 1px; opacity: 0.1;'
				/>}
			</> : <>
				{user && user.id && <img
					src={`${apiServer}/api/pageview/iscordova.false/${user.id}/${activeTeam?.id}/${new Date().getTime()}`}
					style='width: 1px; height: 1px; opacity: 0.1;'
				/>}
			</>}
		</>);
	}
}


@observer
class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

	toggleDashboard = () => {
		const { appState } = this.props.stores;
		appState.toggleDashboard();
	}

	search = () => {
		const { searchText } = this.state;
		this.toggleDashboard();
		route(`/search/${searchText}`);
	}

    reloadApp = () => {
        window.location.reload(true);
    }

    render() {
		const { currentUrl } = this.props;
		const { searchText } = this.state;
		const { appState, userStore } = this.props.stores;
		const { user = {}, isAdmin, isTester } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
		const {
			path, isCordova, apiServer, webocketUrl, apiServerChanged, showDashoard,
			showDrawer, drawerComponent, drawerProps = {},
			showDrawer2, drawerComponent2, drawerProps2 = {},
			showDrawer3, drawerComponent3, drawerProps3 = {},
			drawerHeightMedium,
			currentVersion, latestVersion,
		} = appState;

		let pathFixed = path.replace(/^\//, '');
		pathFixed = pathFixed.replace(/\/$/, '');
		pathFixed = pathFixed.replace(/\w{8,}-\w{4,}-\w{3,}-\w{3,}-\w{12,}/, 'uuid');
		pathFixed = pathFixed.replace(/\d+/g, 'id');
		// 8086094d-fbc3-44af-acc0-7c1ae9b5dce7
		pathFixed = pathFixed.replace(/\/$/, '');
		pathFixed = pathFixed.replace(/\//g, '.');

		if (pathFixed === '') {
			pathFixed = 'frontpage';
		}

		if (/^\/wizard/.test(currentUrl)) {
			return <>
				{user && user.id && path && <img src={`${apiServer}/api/pageview/${pathFixed}/${user.id}/${activeTeam?.id}/${new Date().getTime()}`} style='width: 1px; height: 1px; opacity: 0.1;' />}
			</>;
		}

		return (<>
			{[1, 2, 3, 4, 5, 6, 7, 8, 9].map(i => {
				return (<Fragment key={`drawer-container-${i}`}>
					{appState[`showDrawer${i}`] && <>
						<Drawer
							drawer={i}
							stores={this.props.stores}
							height={appState[`drawerProps${i}`].height}
							key={`drawer-${i}-${appState[`drawerProps${i}`].id}`}
							id={appState[`drawerProps${i}`].id}
							title={appState[`drawerProps${i}`].title}
							editFunction={appState[`drawerProps${i}`].editFunction}
							drawerComponent={appState[`drawerComponent${i}`]}
							onCloseClick={appState[`drawerProps${i}`].onCloseClick}
							borderRadius={appState[`drawerProps${i}`].borderRadius}
							showCloseButton={appState[`drawerProps${i}`].showCloseButton}
							showBackButton={appState[`drawerProps${i}`].showBackButton}
							backButtonText={appState[`drawerProps${i}`].backButtonText}
							callback={appState[`drawerProps${i}`].callback}
						>
							{appState[`drawerComponent${i}`] === 'bugReport' && <BugReport stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'settings' && <Settings stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'menu' && <Menu stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'search' && <Search stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'sendEmail' && <SendEmail stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'sendSms' && <SendSms stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'adminNewUser' && <AdminNewUser stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewNews' && <AdminNewNews stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewCustomer' && <AdminNewCustomer stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}

							{appState[`drawerComponent${i}`] === 'adminNewArticle' && <AdminNewArticle stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewTextTemplate' && <AdminNewTextTemplate stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewSms' && <AdminNewSms stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewApplicationOffer' && <AdminNewApplicationOffer stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewSubmittedApplication' && <AdminNewSubmittedApplication stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}

							{appState[`drawerComponent${i}`] === 'adminEditUser' && <AdminNewUser stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditNews' && <AdminNewNews stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditCustomer' && <AdminNewCustomer stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}

							{appState[`drawerComponent${i}`] === 'adminEditArticle' && <AdminNewArticle stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditTextTemplate' && <AdminNewTextTemplate stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditSms' && <AdminNewSms stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditApplicationOffer' && <AdminNewApplicationOffer stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditSubmittedApplication' && <AdminNewSubmittedApplication stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}

							{appState[`drawerComponent${i}`] === 'help' && <Help stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'addArrayObject' && <AddArrayObject stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'editArrayObject' && <EditArrayObject stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'addArrayElement' && <AddArrayElement stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}

							{appState[`drawerComponent${i}`] === 'viewImage' && <ImageView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'imageFullscreen' && <ImageFullscreen stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'viewNews' && <NewsView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'newsMenu' && <NewsMenu stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'newsEdit' && <NewsEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'newsNew' && <NewsEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminListNews' && <NewsAdminList stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'editArticle' && <ArticleEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newArticle' && <ArticleEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'viewArticle' && <ArticleView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'listArticle' && <ArticleList stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'adminListArticle' && <ArticleAdminList stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'viewApplicationOffer' && <ApplicationOfferView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'editApplicationOffer' && <ApplicationOfferEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'editApplicationOfferSettings' && <ApplicationOfferEditSettings stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'newSubmittedApplication' && <SubmittedApplicationEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'editSubmittedApplication' && <SubmittedApplicationEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'viewSubmittedApplication' && <SubmittedApplicationView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'viewSubmittedApplicationAdmin' && <SubmittedApplicationViewAdmin stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'editTextTemplate' && <TextTemplateEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newTextTemplate' && <TextTemplateEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'viewTextTemplate' && <TextTemplateView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'viewMailSent' && <MailSentView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'viewSms' && <SmsView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'viewCustomer' && <CustomerView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'editCustomer' && <CustomerEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'editCustomerUser' && <CustomerUserEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newCustomerUser' && <CustomerUserEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}

							{appState[`drawerComponent${i}`] === 'viewText' && <ViewText stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
						</Drawer>
					</>}
				</Fragment>);
			})}

			{[1, 2, 3, 4, 5].map(i => {
				return (<Fragment key={`drawer-right-container-${i}`}>
					{appState[`showDrawerRight${i}`] && <>
						<DrawerRight
							drawer={i}
							stores={this.props.stores}
							height={appState[`drawerProps${i}`].height}
							id={appState[`drawerProps${i}`].id}
							key={`drawer-right--${i}-${appState[`drawerProps${i}`].id}`}
							title={appState[`drawerProps${i}`].title}
							editFunction={appState[`drawerProps${i}`].editFunction}
							drawerComponent={appState[`drawerComponent${i}`]}
							onCloseClick={appState[`drawerProps${i}`].onCloseClick}
							borderRadius={appState[`drawerProps${i}`].borderRadius}
							showCloseButton={appState[`drawerProps${i}`].showCloseButton}
							showBackButton={appState[`drawerProps${i}`].showBackButton}
							backButtonText={appState[`drawerProps${i}`].backButtonText}
							rightButtonText={appState[`drawerProps${i}`].rightButtonText}
							rightButtonClick={appState[`drawerProps${i}`].rightButtonClick}
							callback={appState[`drawerProps${i}`].callback}
						>
							{appState[`drawerComponent${i}`] === 'search' && <Search stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'fileViewer' && <FileViewer stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'urlViewer' && <UrlViewer stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'viewImage' && <ImageView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'viewApplicationOffer' && <ApplicationOfferView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
						</DrawerRight>
					</>}
				</Fragment>);
			})}

			<div
				class={`navbar-themed fixed-bottom pb-0 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}
				style={`
					font-size: ${FONTSIZE_MENU};
				`}
			>
                {/* <MenuV2 stores={this.props.stores} pathFixed={pathFixed} isCordova={isCordova} /> */}

				{isAdmin && apiServerChanged && <div class='position-absolute badge badge-primary' style='bottom: 2px; right: 0;'>
					<span class='font-weight-lighter'>apiServer:</span> {apiServer} -
					<span class='font-weight-lighter'>webocketUrl:</span> {webocketUrl}
				</div>}

				{currentVersion < latestVersion && <>
					<div
						class='position-absolute badge badge-success rounded-pill px-3 py-2'
						style='bottom: 100px; left: 50%; transform: translateX(-50%); font-size: 0.9rem; line-height: 1.0rem; cursor: pointer;'
						onClick={this.reloadApp}
					>
						<div class='d-flex flex-row'>
							<i class='fa-duotone fa-face-smile-hearts' style='font-size: 2.2rem;' />
							<div class='ml-3 text-left'>
								<span class='font-weight-bold'>
									<Text id='version.new-available'>New version available</Text>
								</span>
								<br />
								<span class='font-weight-normal'>
									<Text id='version.click-to-update'>Click here or refresh page to update.</Text>
								</span>
								<br />
								{/* <div class='font-weight-lighter text-center'>
									<small>
										{currentVersion} <i class='fa-duotone fa-arrow-right' /> {latestVersion}
									</small>
								</div> */}
							</div>
						</div>
						{/* {JSON.stringify(currentVersion)}
						{JSON.stringify(latestVersion)} */}
					</div>
				</>}

			</div>
		</>);
	}
}

export default Navigation;
