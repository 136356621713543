import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import ImageScroller from '../../components/imagescroller';

import localUtil from '../../lib/util';

function viewImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3LargeLink}`;
    return imgSrc;
}

@observer
class GuiViewText extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {
            title,
            ingress,
            body,
            images = [],
            buttonClass,
            buttonText,
            buttonIcon,
            buttonFunc,
            // checksum,
        } = this.props;
        const { userStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class='d-flex'>
                                {/* {JSON.stringify(part)} */}
                                <ImageScroller images={images} stores={this.props.stores} showImg={img => viewImg(img, this.props, '1920x')} />
                            </div>
                            <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                <div class='d-flex flex-column mb-3 font-weight-bold'>
                                    <Markdown markdown={localUtil.replaceImages(ingress, images, language, false)} markedOpts={localUtil.getMarkdownOptions()} />
                                </div>
                                <div class='d-flex flex-column'>
                                    <Markdown markdown={localUtil.replaceImages(body, images, language, false)} markedOpts={localUtil.getMarkdownOptions()} />
                                </div>
                            </div>

                            {buttonText && <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                <button class={`${buttonClass} rounded-pill`} onClick={buttonFunc}>
                                    {buttonIcon && <i class={`mr-2 ${buttonIcon}`} />}
                                    {buttonText}
                                </button>
                            </div>}

                        </div>
                    </div>

                    {/* <SimpleBlog stores={this.props.stores} {...this.props} /> */}
                </div>
            </div>
        </>);
    }
}

export default GuiViewText;
