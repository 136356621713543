import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    textTemplateNumber: <Text id='input.textTemplateNumber-help'>textTemplateNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class NewTextTemplate extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('textTemplate'),
        };
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id } = props;
        const { textTemplateStore } = this.props.stores;
        if (emptyFirst) {
            textTemplateStore.updateKeyValue('textTemplate', {});
        }
        await textTemplateStore.load(id, false, { addData: ['tags'], query: {} });
    }

    createTextTemplate = async () => {
        const { callback = () => {} } = this.props;
        const { textTemplateStore } = this.props.stores;
        const { newTextTemplate } = textTemplateStore;

        const response = await textTemplateStore.insert(newTextTemplate);
        const textTemplate = response?.data?.textTemplate;

        textTemplateStore.updateKeyValue('newTextTemplate', {});
        callback();
    }

    chooseItem = (e) => {
        const { id } = e.target.closest('.similar-item').dataset;
        const { textTemplateStore } = this.props.stores;
        const { foundList } = textTemplateStore;
        const item = foundList.find((item) => item.id === parseInt(id, 10));
        textTemplateStore.updateKeyValue('newTextTemplate', {
            ...item,
        });
        textTemplateStore.resetSearch();
    }

    toggleSimilar = () => {
        const { showSimilar } = this.state;
        this.setState({
            showSimilar: !showSimilar,
        });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { week, isNew, drawerLevel, lookupArray } = this.props;
        const { sections, showSimilar } = this.state;
        const { userStore, textTemplateStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { newTextTemplate, foundList, foundListKeys } = textTemplateStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center position-relative'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {isNew ? <Text id='textTemplate.new'>New textTemplate</Text> : <Text id='textTemplate.edit'>Edit textTemplate</Text>}
                    </div>

                    {showSimilar && foundList?.length > 0 && <>
                        <div class='w-100 d-flex flex-column justify-content-start'>
                            <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                                <div class='mx-4 font-weight-lighter px-3 py-1 text-muted' >
                                    <small class='text-uppercase'>
                                        <Text id='textTemplate.similar'>Similar textTemplates</Text>
                                    </small>
                                </div>
                                <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-4 d-flex flex-column px-0 overflow-auto`} style='font-size: 1.1em; border-radius: 15px; max-height: 35vh;'>
                                    {foundList.map((item, idx) => {
                                        return(<>
                                            <div class='d-flex flex-row justify-content-between align-items-center'>
                                                <div
                                                    class='flex-fill similar-item py-2 px-3'
                                                    data-id={item.id}
                                                    onClick={this.chooseItem}
                                                >
                                                    {item.name}
                                                    <span class='font-weight-lighter text-muted ml-3'>{item.textTemplateNumber}</span>
                                                </div>
                                                {/* {item.images[0] && <>
                                                    <div>
                                                        <div class=''>
                                                            <img src={`${item.images[0].s3MediumLink}`} class='rounded-lg img-fluid' style='max-height: 45px;' />
                                                        </div>
                                                    </div>
                                                </>} */}
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>
                        </div>
                    </>}

                    <div class='w-100 d-flex flex-column justify-content-start'>
                        <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                            <FormFields
                                stores={this.props.stores}
                                sections={sections}
                                holdingStore={textTemplateStore}
                                objectName={isNew ? 'newTextTemplate' : 'textTemplate'}
                                isNew={isNew}
                                drawerLevel={drawerLevel}
                                isAdmin={false}
                                hideAdmin={true}
                                lookupArray={lookupArray}
                                rightHeader={isNew && foundList?.length > 0 ? <span class='text-success font-weight-bold'><i class='fa-solid fa-circle-info' /> Similar textTemplates ({foundList.length})</span> : null}
                                rightHeaderClick={this.toggleSimilar}
                            />
                        </div>
                    </div>
                </div>



            </div>

            {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                <button type='button' class='btn btn-primary rounded-pill' onClick={this.createTextTemplate}>
                    {isNew ? <Text id='textTemplate.create'>Create textTemplate</Text> : <Text id='textTemplate.save'>Save textTemplate</Text>}
                </button>
            </div>}
        </>);
    }
}

export default NewTextTemplate;
