import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import versionData from '../../version.json';

import MenuList from './list';

import localUtil from '../../lib/util';

@observer
class Menu extends Component {
	constructor(props) {
        super(props);
        const { appState, userStore } = props.stores;
        const { isDevelopment, opts = {} } = appState;
        const { customer, user, isAdmin } = userStore;
        const { featureFlags = {} } = customer;
        const { isSoknadsguideadmin, isApproved } = user;

        this.state = {
            menuLinks: [
                {
                    name: <Text id='menu.main-content'>Main menu</Text>,
                    // help: <Text id='menu.add-new-content-help'>Choose what kind of content you want to create.</Text>,
                    links: [
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.admin'>Admin</Text>,
                            href: '/admin/',
                            icon: 'fa-solid fa-user-police',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.errors'>Errors</Text>,
                            href: '/errors/',
                            icon: 'fa-solid fa-bug',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.apilogger'>API logger</Text>,
                            href: '/apilogger/',
                            icon: 'fa-solid fa-list',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.querylogger'>Query logger</Text>,
                            href: '/querylogger/',
                            icon: 'fa-solid fa-list',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.smslist'>SMS List</Text>,
                            href: '/sms/',
                            icon: 'fa-solid fa-list',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.mailsentlist'>mailsent List</Text>,
                            href: '/mailsent/',
                            icon: 'fa-solid fa-list',
                        },
                        {
                            name: <Text id='menu.my-page'>My page</Text>,
                            href: '/users/profile',
                            icon: 'fa-solid fa-user-vneck',
                        },
                        {
                            hasToBeAdmin: !opts.showApplicationOffer,
                            name: <Text id='menu.applicationOffer'>Application Offer</Text>,
                            href: '/applicationoffer',
                            icon: 'fa-solid fa-lightbulb',
                            type: 'applicationoffer',
                        },
                        {
                            hasToBeAdmin: !isSoknadsguideadmin,
                            name: <Text id='menu.submittedApplicationList'>Incoming applications</Text>,
                            href: '/submittedapplication/customerlist',
                            icon: 'fa-solid fa-lights-holiday',
                            type: 'submittedapplication',
                        },
                        {
                            hasToBeAdmin: !isSoknadsguideadmin,
                            name: <Text id='menu.texttemplates'>Text Templates</Text>,
                            href: '/texttemplates',
                            icon: 'fa-solid fa-text-size',
                        },
                        {
                            hasToBeAdmin: false,
                            name: <Text id='menu.news'>News</Text>,
                            href: '/news',
                            icon: 'fa-solid fa-newspaper',
                            type: 'news',
                        },
                        {
                            name: <Text id='menu.help'>Help</Text>,
                            func: this.showHelp,
                            icon: 'fa-duotone fa-life-ring text-',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.report-bug'>Report Bug</Text>,
                            func: this.reportBug,
                            icon: 'fa-solid fa-bug',
                        },
                        {
                            name: <Text id='menu.reload'>Reload</Text>,
                            func: this.reloadApp,
                            icon: 'fa-solid fa-arrows-rotate',
                        },
                        {
                            name: <Text id='menu.logout'>Logout</Text>,
                            href: '/logout',
                            icon: 'fa-solid fa-right-from-bracket',
                        },
                    ],
                },
            ],
            menuLinksNotLoggedIn: [
                {
                    name: <Text id='menu.main-content'>Main menu</Text>,
                    // help: <Text id='menu.add-new-content-help'>Choose what kind of content you want to create.</Text>,
                    links: [
                        {
                            name: <Text id='menu.help'>Help</Text>,
                            func: this.showHelp,
                            icon: 'fa-duotone fa-life-ring',
                        },
                        {
                            hasToBeAdmin: false,
                            name: <Text id='menu.news'>News</Text>,
                            href: '/news',
                            icon: 'fa-solid fa-newspaper',
                            type: 'news',
                        },
                        {
                            name: <Text id='menu.reload'>Reload</Text>,
                            func: this.reloadApp,
                            icon: 'fa-solid fa-arrows-rotate',
                        },
                        {
                            name: <Text id='menu.login'>Login</Text>,
                            func: this.gotoLogin,
                            icon: 'fa-solid fa-right-to-bracket',
                        },
                    ],
                },
            ],
        };
    }

    gotoLogin = () => {
        const { appState } = this.props.stores;
        const { path } = appState;
        appState.setWantedPage(path);
        route('/login');
    }

    reloadApp = () => {
        window.location.reload(true);
    }

    reportBug = () => {
        const { drawerLevel = 1 } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('bugReport', {
			height: drawerHeightMedium,
        }, drawerLevel + 1);
    }

    showHelp = () => {
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightMediumLarge } = appState;
        appState.toggleDrawer(false, drawerLevel);
        appState.openDrawer('help', {
			height: drawerHeightMediumLarge,
        }, drawerLevel + 1);
    }

    selectView = (e) => {
        const { appState } = this.props.stores;
        const { type } = e.target.closest('button').dataset;
        appState.setViewKey('clinic', type);
    }

    toggleAdminFields = () => {
        const { appState } = this.props.stores;
        const { showAdminFields } = appState;
        appState.setShowAdminFields(!showAdminFields);
    }

    loadAll = async () => {
        const { appState } = this.props.stores;
        const { isDevelopment, opts = {} } = appState;
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { menuLinks, menuLinksNotLoggedIn } = this.state;
        const { drawerLevel } = this.props;
        const { appState, userStore } = this.props.stores;
        const { isCordova, languageList, language, opts = {}, isDevelopment, view = {}, showAdminFields } = appState;
        const { user, isAdmin, saved, customer } = userStore;
        const { language: userLanguage } = user;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        if (!user.id) {
            return (<>
                <div class='bg-light d-flex flex-column justify-content-start pt-4' style='min-height: 100%;'>
                    <MenuList stores={this.props.stores} menus={menuLinksNotLoggedIn} drawerLevel={drawerLevel} />
                    <div class='d-flex flex-row justify-content-center align-items-center mt-3'>
                        {languageList.map(lang => {
                            return (<>
                                <button
                                    type='button'
                                    class={`btn btn-link ${language === lang[0] ? 'text-primary font-weight-bold' : 'text-muted'}`}
                                    onClick={() => appState.setLanguage(lang[0])}
                                >
                                    {lang[2]} {lang[1]}
                                </button>
                            </>);

                        })}
                    </div>
                </div>
            </>);
        }

        return (
            <div class='bg-light d-flex flex-column justify-content-start pt-4' style='min-height: 100%;'>

                <MenuList stores={this.props.stores} menus={menuLinks} drawerLevel={drawerLevel} />

                <div class='d-flex flex-row justify-content-center align-items-center mt-3'>
                    {languageList.map(lang => {
                        return (<>
                            <button
                                type='button'
                                class={`btn btn-link ${userLanguage === lang[0] ? 'text-primary font-weight-bold' : 'text-muted'}`}
                                onClick={() => userStore.setLanguage(lang[0])}
                            >
                                {lang[2]} {lang[1]}
                            </button>
                        </>);

                    })}
                </div>
                {isAdmin && <>
                    <div class='d-flex flex-row justify-content-center align-items-center mt-1'>
                        <span
                            class={`badge badge-pill badge-${showAdminFields ? 'danger' : 'light'} font-weight-bold mr-2 mt-2`}
                            onClick={this.toggleAdminFields}
                        >Show Admin</span>
                    </div>
                </>}
                <div class='d-flex flex-row justify-content-center align-items-center mt-3'>
                    <small>
                        Build: {versionData.build}, <span class='font-weight-lighter'>{util.isoDate(versionData.date)}</span>
                    </small>
                </div>
            </div>
        );
    }
}

export default Menu;
