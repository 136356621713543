import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import ImageScroller from '../../components/imagescroller';

import VideoStreamingPlayer from '../../routes/videostreaming/player';
import YourSubmittedApplicationList from '../../components/submittedapplication/yourList';

import localUtil from '../../lib/util';

function partImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3LargeLink}`;
    return imgSrc;
}

@observer
class ApplicationOfferView extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { id } = props;
        const { applicationOfferStore, customerStore } = this.props.stores;
        if (id) {
            applicationOfferStore.updateKeyValue('applicationOffer', {});
            await applicationOfferStore.load(id, false, { addData: ['tags'] });

            const { applicationOffer } = applicationOfferStore;
            const currentDate = new Date();
            const isOpenForApplications = new Date(applicationOffer.startDate) <= currentDate && currentDate <= new Date(applicationOffer.endDate);
            this.setState({ isOpenForApplications, currentDate });

            const { customer } = applicationOffer;
            await customerStore.loadPublic(customer);
            const { customerPublic = {} } = customerStore;
            if (customerPublic?.images && customerPublic.images.length > 0) {
                applicationOfferStore.updateKeyValue('customerLogoHeight', 80);
            }
            await this.loadSubmittedApplications();
        }
    }

    loadSubmittedApplications = async (props = this.props) => {
        const { id } = props;
        if (id) {
            const { submittedApplicationStore } = this.props.stores;
            const submittedApplications = await submittedApplicationStore.load({ query: { applicationOffer: id }, skipUpdate: true });
            this.setState({ submittedApplications });
        }
    }

    viewTags = obj => {
        if (obj.tags?.length === 0 && obj.loans?.length === 0 && !obj.ownedByOther && !obj.url && !obj.parents?.length > 0 && !obj.files?.length > 0) {
            return '';
        }
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tagClass = darkmode ? 'dark' : 'light';

        return (<>
            <div class='w-100 overflow-hidden'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {obj.files?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-file' /> <span class='font-weight-lighter'> x {obj.files.length}</span>
                        </span>
                    </>}
                    {obj.tags?.length > 0 && obj.tags.map(tag => {
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    viewSubmittedApplication = id => {
        const { appState, userStore, submittedApplicationStore } = this.props.stores;
        const { drawerLevel = 1, callback = () => {} } = this.props;
        const { drawerHeightMediumLarge } = appState;

        appState.openDrawer('viewSubmittedApplication', {
            id,
            height: drawerHeightMediumLarge,
            editFunction: this.editSubmittedApplicationView,
            callback: () => {
                appState.toggleDrawer();
                callback();
                this.loadAll();
            },
        }, drawerLevel + 1);
    }

    editSubmittedApplicationView = e => {
        // return;
        const { id, title } = e.target.closest('button').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState, mediasStore } = this.props.stores;
        const { drawerHeightMediumLarge } = appState;
        appState.openDrawer('editSubmittedApplication', {
            id,
            height: drawerHeightMediumLarge,
            callback: () => {
                appState.toggleDrawer(false, drawerLevel + 2);
                // this.loadAll();
            },
        }, drawerLevel + 2);
    }

    writeApplication = () => {
		const { id, drawerLevel = 1 } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
		appState.openDrawer('newSubmittedApplication', {
			height: drawerHeightMediumLarge,
            isNew: true,
            applicationOfferId: id,
            callback: (id) => {
                if (!id) {
                    return;
                }
                appState.toggleDrawer(drawerLevel + 1);
                setTimeout(() => {
                    this.viewSubmittedApplication(id);
                }, 500);
                this.setState({ applicationSent: true });
                setTimeout(() => {
                    this.setState({ applicationSent: false });
                }, 5000);
                this.loadAll();
            }
		}, drawerLevel + 1);
	}

    editApplicationOffer = e => {
        // return;
        const { id, title } = e.target.closest('button').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, mediasStore } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
		appState.openDrawer('editApplicationOffer', {
            id,
			height: drawerHeightMediumLarge,
            callback: this.closeAddMedia,
		}, drawerLevel + 2);
	}

    gotoLogin = () => {
        const { appState } = this.props.stores;
        const { path } = appState;
        appState.setWantedPage(path);
        route('/login');
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            page,
            artid,
            bib,
            showAnimations = true,
            paddingTop = 60,
            drawerLevel = 1,
        } = this.props;
        const { sessionid, showMenu, submittedApplications, applicationSent, isOpenForApplications, currentDate } = this.state;
        const { appState, userStore, applicationOfferStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { isSoknadsguideadmin } = user;

        const { applicationOffer } = applicationOfferStore;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style={`max-width: 960px; padding-top: ${paddingTop}px; padding-bottom: 100px;`}>

                    <div class={`d-flex justify-content-start flex-row w-100`}>
                        <div class='flex-fill'>
                            {/* <h5 class='ml-3 mb-3'>{applicationOffer.title}</h5> */}
                        </div>
                        {/* <div class='' style='margin-right: 65px;'>
                            <span onClick={this.editApplicationOffer} class={`text-primary ${linkTextClass}`} style={`${linkTextStyle}`}>
                                <i class={`fa-regular fa-pen ml-3 mr-1`} />
                            </span>
                        </div> */}
                    </div>

                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class='d-flex flex-column'>
                                {/* {JSON.stringify(part)} */}
                                {applicationOffer?.files?.length > 0 && applicationOffer.files.map(file => {
                                    const poster = file.s3LinkStreamPreview1;
                                    // console.log({ file });
                                    if (file.s3LinkStream) {
                                        return (<>
                                            <div class='video-container rounded-lg'>
                                                <VideoStreamingPlayer
                                                    key={file.s3LinkStream}
                                                    videoStreamUrl={file.s3LinkStream}
                                                    stores={this.props.stores}
                                                    poster={poster}
                                                />
                                            </div>
                                        </>);
                                    }
                                    if (file.s3Link.match(/\.(m4v|m4a|mp4)$/i)) {
                                        return (<>
                                            <div className='video-container rounded-lg'>
                                                <video controls className='video-player'>
                                                    <source src={file.s3Link} type='video/mp4' />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        </>);
                                    }
                                    if (file.s3Link.match(/\.(mov)$/i)) {
                                        return (<>
                                            <div className='video-container rounded-lg'>
                                                <video controls className='video-player'>
                                                    <source src={file.s3Link} type='video/quicktime' />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                            <div class='video-info d-flex flex-row justify-content-center'>
                                                <span class='text-muted'>size:</span> {localUtil.formatFileSize(file.size)}
                                                <span class='text-muted ml-3 mr-1'>encoding:</span> {file.encoding}
                                                <span class='text-muted ml-3 mr-1'>mimetype:</span> {file.mimetype}
                                                <span class='text-muted ml-3 mr-1'>ext:</span> {file.ext}
                                                <span class='text-muted ml-3 mr-1'>s3Link:</span> <a href={file.s3Link} target='_blank' rel='noopener noreferrer'>download</a>
                                            </div>
                                        </>);
                                    }
                                })}
                                <ImageScroller
                                    images={applicationOffer.images}
                                    stores={this.props.stores}
                                    showImg={img => partImg(img, this.props, '1920x')}
                                    maxHeight={'50vh'}
                                />
                            </div>
                            <div class={`d-flex flex-column mx-3 mt-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                {/* <div class='font-weight-lighter'>
                                    {util.formatDate(applicationOffer.createdDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                </div> */}
                                <div class='d-flex flex-column mb-3 font-weight-bold'>
                                    <Markdown markdown={localUtil.replaceImages(applicationOffer.ingress, applicationOffer.images, language, false)} markedOpts={localUtil.getMarkdownOptions()} />
                                </div>
                                <div class='d-flex flex-column'>
                                    <Markdown markdown={localUtil.replaceImages(applicationOffer.body, applicationOffer.images, language, false)} markedOpts={localUtil.getMarkdownOptions()} />
                                </div>
                            </div>

                            {applicationOffer?.files && applicationOffer.files.length > 0 && <>
                                <div class={`d-flex flex-column mx-3 mt-4 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                    <div class='d-flex flex-column'>
                                        {applicationOffer.files.map(file => {
                                            return (<>
                                                <a href={file.s3Link} class='badge badge-pill badge-light border mr-2' target='_blank' rel='noopener noreferrer'>{file.name} <span class='font-weight-lighter'>({localUtil.formatFileSize(file.size)})</span></a>
                                            </>);
                                        })}
                                    </div>
                                </div>
                            </>}

                            {applicationOffer.tags && applicationOffer.tags.length > 0 && <div class={`d-flex flex-column mx-3 mt-4 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                {this.viewTags(applicationOffer)}
                            </div>}
                        </div>
                    </div>

                    <div class='w-100 d-flex flex-column mt-5 px-3'>
                        {isOpenForApplications ? <>
                            {user.id ? <>
                                <button
                                    type='button'
                                    class='btn btn-primary rounded-pill mt-3'
                                    onClick={this.writeApplication}
                                >
                                    <i class='fa-duotone fa-file-alt mr-2' />
                                    <Text id='applicationOffer.write-application'>Write application</Text>
                                </button>
                                {applicationSent && <>
                                    <div class='alert alert-success rounded-lg mt-3' role='alert'>
                                        <Text id='applicationOffer.application-save'>Application is saved</Text>
                                    </div>
                                </>}
                            </> : <>
                                <button
                                    type='button'
                                    class='btn btn-primary rounded-pill mt-3'
                                    onClick={this.gotoLogin}
                                >
                                    <i class='fa-duotone fa-right-to-bracket mr-2' />
                                    <Text id='applicationOffer.goto-login'>Goto login</Text>
                                </button>
                            </>}
                        </> : <>
                            <div class='alert alert-warning rounded-lg mt-3 text-center' style='font-size: 1.3em;' role='alert'>
                                <Text id='applicationOffer.closed-for-applications'>Closed for applications!</Text>
                            </div>
                            {isSoknadsguideadmin ? <>
                                <button
                                    type='button'
                                    class='btn btn-danger rounded-pill mt-3'
                                    onClick={this.writeApplication}
                                >
                                    <i class='fa-duotone fa-file-alt mr-2' />
                                    <Text id='applicationOffer.write-application-as-admin'>Write application as admin</Text>
                                </button>
                                {applicationSent && <>
                                    <div class='alert alert-success rounded-lg mt-3' role='alert'>
                                        <Text id='applicationOffer.application-save'>Application is saved</Text>
                                    </div>
                                </>}
                            </> : <></>}
                        </>}
                    </div>

                    <YourSubmittedApplicationList
                        drawerLevel={drawerLevel}
                        list={submittedApplications}
                        stores={this.props.stores}
                        classNames={'mt-5'}
                        hideEmpty={true}
                        applicationOffer={applicationOffer.id}
                        callback={this.loadSubmittedApplications}
                    />

                    {/* <SimpleBlog stores={this.props.stores} {...this.props} /> */}
                </div>
            </div>
        </>);
    }
}

export default ApplicationOfferView;
