import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class ApplicationOfferStore extends LocalModel {
    constructor() {
        super('applicationOffer', {
            namePlural: 'applicationOffers',
            namePluralReal: 'applicationOffers',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/applicationoffers/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/applicationoffers/',
                    params: {},
                },
                save: {
                    url: '/api/applicationoffers/',
                    params: {},
                },
                delete: {
                    url: '/api/applicationoffers/',
                    params: {},
                },
            },
        });
    }

    @observable newApplicationOffer = {};

    @observable applicationOffer = {};

    @observable applicationOffers = [];

    @observable applicationOffersCustomerList = [];

    @observable tags = [];

    @observable customerLogoHeight = 0;

    @observable customerid = 0;

    @observable isApplicationOfferAdmin = false;

    @action
    checkApplicationOfferAdmin = async (userId) => {
        this.applicationOffer.members.forEach((member) => {
            if (member.id === userId && member.role === 'admin') {
                this.isApplicationOfferAdmin = true;
            }
        });
    }

}

const store = new ApplicationOfferStore();
export default store;
