import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import { CalendarStore } from '../../stores/calendar';

function formatDate(date) {
    return new Intl.DateTimeFormat('nb-NO', { year: 'numeric', month: 'long' }).format(date);
}

function getMonthName(month) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    return monthNames[month - 1];
}

@observer
class CalendarDatePicker extends Component {
  	constructor(props) {
        super(props);
        this.state = {};

        this.calendarStore = new CalendarStore();
    }

    async updateDate() {
        const { calendarDate } = this.calendarStore;

        const {
            field,
            object,
            onInput,
            holdingStore,
            isAdmin,
            objectName = holdingStore.name,
            checkValue = () => {},
        } = this.props;
        const value = util.isoDate(calendarDate);
        // Update local values
        if (holdingStore) {
            if (isAdmin) {
                if (object.id) {
                    holdingStore.adminUpdateLocal(object.id, { [field]: value }, objectName);
                    await holdingStore.adminUpdate(object.id, { [field]: value }, objectName);
                } else {
                    holdingStore.updateObjectKeyValue(objectName, field, value);
                }
            } else {
                holdingStore.updateObjectKeyValue(objectName, field, value);
                if (object.id) {
                    await holdingStore.saveField(object.id, field, value);
                }
            }
        }
        checkValue();
    }

    clickDay(day) {
        this.calendarStore.calendarSetDate(day);
        this.calendarStore.calendarChangeView('showDay');
        this.updateDate();
    }

    chooseYear(year) {
        this.calendarStore.calendarSetYear(year);
        this.updateDate();
    }

    chooseMonth(month) {
        this.calendarStore.calendarSetMonth(month - 1);
        this.updateDate();
    }

    loadAll(props = this.props) {
        const { object, field, value } = props;

        // console.log('loadAll', { field, object, value });
        if (value) {
            this.calendarStore.calendarSetDate(value);
        }
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {
    }

    render() {
        const { calendarSelectDateTime, value, skipSurroundingDivs, min, max } = this.props;
        const { appState, userStore } = this.props.stores;
        const {
            colors,
            viewName,
            calendarDate,
            calendarDateStart,
            calendarDateEnd,
            isToday,
            isTodayWeek,
            currentMonth,
            currentYear,
            yearRange,
            monthRange,
        } = this.calendarStore;
        const { calendars } = this.calendarStore;
        const { user = {}, isAdmin, isTester } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const minDate = min ? new Date(min) : null;
        const maxDate = max ? new Date(max) : null;

        return (<>
            <div class='w-100 h-100 d-flex justify-content-center'>
                <div class='w-100 h-100'>
                    <div class={`d-flex flex-row w-100 justify-content-between ${skipSurroundingDivs ? '' : 'mt-2'}`}>
                        {/* <div>
                            <button class={`btn btn-outline-primary rounded-pill-left`} onClick={() => this.calendarStore.calendarPrev()}>
                                <i class='fa-duotone fa-caret-left' /> <Text id='calendar.prev'>Prev</Text>
                            </button>
                            <button class={`btn btn-${isToday ? 'primary' : 'outline-primary'} rounded-none`} onClick={() => this.calendarStore.calendarToday()}><Text id='calendar.today'>Today</Text></button>
                            <button class={`btn btn-outline-primary rounded-pill-right`} onClick={() => this.calendarStore.calendarNext()}>
                                <Text id='calendar.next'>Next</Text> <i class='fa-duotone fa-caret-right' />
                            </button>
                        </div> */}
                        {viewName === 'showMonth' && <>
                            <div class='d-flex flex-column w-50 position-relative'>
                                <div class='d-flex position-absolute' style='top: 0px; right: 0px;'>
                                    <button class={`btn btn-link btn-sm text-secondary`} onClick={() => this.calendarStore.calendarChangeView()}>
                                        <i class='fa-regular fa-times' />
                                    </button>
                                </div>


                                <div class='d-flex flex-row w-100 justify-content-center'>
                                    <div class='d-flex'>
                                        <div class='form-group'>
                                            <select class='form-control form-control-sm' onInput={e => this.chooseMonth(e.target.value)}>
                                                {monthRange.map((month, idx) => {
                                                    return (<>
                                                        <option value={month} selected={month === currentMonth}>{getMonthName(month)}</option>
                                                    </>);
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div class='d-flex ml-2'>
                                        <div class='form-group'>
                                            <select class='form-control form-control-sm' onInput={e => this.chooseYear(e.target.value)}>
                                                {yearRange.map((year, idx) => {
                                                    return (<>
                                                        <option selected={year === currentYear}>{year}</option>
                                                    </>);
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class='table-responsive table-sm mt-1'>
                                    <table class='table table-sm table-bordered table-hover'>
                                        <thead>
                                            <tr>
                                                <th class='text-center'><Text id='calendar.week'>Week</Text></th>
                                                <th class='text-center'><Text id='calendar.monday-short'>M</Text></th>
                                                <th class='text-center'><Text id='calendar.tuesday-short'>T</Text></th>
                                                <th class='text-center'><Text id='calendar.wednesday-short'>W</Text></th>
                                                <th class='text-center'><Text id='calendar.thursday-short'>T</Text></th>
                                                <th class='text-center'><Text id='calendar.friday-short'>F</Text></th>
                                                <th class='text-center'><Text id='calendar.saturday-short'>S</Text></th>
                                                <th class='text-center'><Text id='calendar.sunday-short'>S</Text></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.calendarStore.weeksInMonth().map((obj, idx) => {
                                                if (!obj.week) {
                                                    return '';
                                                }
                                                return (<>
                                                    <tr>
                                                        <td class='text-center font-weight-lighter'>
                                                            {obj.week?.week}
                                                        </td>
                                                        {obj.week?.daysInWeek.map((day, idx) => {
                                                            const dayOfWeek = day.getDay();
                                                            const isToday = day.toDateString() === new Date().toDateString();
                                                            const currentIsToday = calendarDate.toDateString() === new Date().toDateString();
                                                            const isCurrentDay = day.toDateString() === calendarDate.toDateString() && !currentIsToday;
                                                            const isOutOfRange = (minDate && day < minDate) || (maxDate && day > maxDate);
                                                            return (<>
                                                                <td
                                                                    class={`text-center
                                                                        ${isOutOfRange ? 'text-muted font-weight-italic' : ''}
                                                                        ${(dayOfWeek === 6 || dayOfWeek === 0) ? 'text-danger' : ''}
                                                                        ${isToday ? 'font-weight-bolder' : ''}
                                                                        ${isCurrentDay ? 'bg-primary text-white' : ''}
                                                                    `}
                                                                    style={`
                                                                        ${isToday ? 'font-weight: 1000 !important;' : ''}
                                                                    `}
                                                                    // onClick={() => this.calendarStore.calendarSetDate(day)}
                                                                    onClick={isOutOfRange ? () => {} : () => this.clickDay(day)}
                                                                >
                                                                    {day.getDate()}
                                                                </td>
                                                            </>);
                                                        })}
                                                    </tr>
                                                </>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>}
                    </div>

                    <div class={`d-flex flex-row w-100 justify-content-start ${skipSurroundingDivs ? '' : 'mt-2'} px-0`}>
                        {calendarDate && <>
                            <div
                                class='d-flex flex-row w-100 align-items-center justify-content-between'
                                onClick={() => this.calendarStore.calendarChangeView('showMonth')}
                            >
                                {/* {viewName === 'month' && <>
                                    <span class='mr-2'>{util.formatDate(calendarDate, { locale: 'nb-NO' })}</span>
                                </>}
                                {viewName === 'week' && <>
                                    <span class='mr-2'>{util.formatDate(calendarDate, { locale: 'nb-NO' })}</span>
                                    <span class='mr-2'>w{util.getWeek(calendarDate, { locale: 'nb-NO' })}</span>
                                </>} */}
                                {/* {viewName === 'day' && <> */}

                                {value ? <>
                                    <span class='mr-2'>
                                        {util.formatDate(calendarDate, {
                                            weekday: 'long',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            locale: 'nb-NO',
                                            // hour: '2-digit',
                                            // minute: '2-digit',
                                            // hour12: false,
                                        })}
                                    </span>
                                </> : <>
                                    n/a
                                </>}
                                {/* </>} */}
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </>);
    }
}

export default CalendarDatePicker;
