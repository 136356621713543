import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FastList from '../../components/fastlist';
import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import FileList from '../../components/fileViewer/list';
import GradientRight from '../../components/gui/gradientRight';
import Placeholder from '../../components/gui/placeholder';

const scrollDistance = 210;
const scrollDistanceSnap = 90;
const activationRange = 20;

const fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') {
        dir = -1;
        o = o.substring(1);
    }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => p ? p : n, 0);

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
}))
@observer
class GuiListTicker extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            focusedIndex: 0,
        };
    }

    viewElement = (e, props) => {
        e.stopPropagation();
        e.preventDefault();
        const { swipeIdOpen } = this.state;
        const { id } = props ? props?.id : e?.target.closest('.box-line').dataset;
        const objId = parseInt(id, 10);
        if (swipeIdOpen === objId) {
            this.closeSwipMenu(e);
            return;
        }
        const { view = () => {} } = this.props;
        view(e, props);
    }

    deleteElement = e => {
        const { deleteElement = () => {} } = this.props;
        deleteElement(e);
        this.closeSwipMenu(e);
    }

    rentOutElement = e => {
        const { rentOutElement = () => {} } = this.props;
        rentOutElement(e);
        this.closeSwipMenu(e);
    }

    moveElement = e => {
        const { moveElement = () => {} } = this.props;
        moveElement(e);
        this.closeSwipMenu(e);
    }

    addUsageData = e => {
        const { addUsageData = () => {} } = this.props;
        addUsageData(e);
        this.closeSwipMenu(e);
    }

    checkElement = e => {
        const { checkElement = () => {} } = this.props;
        checkElement(e);
        this.closeSwipMenu(e);
    }

    editElement = e => {
        const { editElement = () => {} } = this.props;
        editElement(e);
        this.closeSwipMenu(e);
    }

    swipeRight1Func = e => {
        const { swipeRight1Func = () => {} } = this.props;
        swipeRight1Func(e);
        this.closeSwipMenu(e);
    }

    swipeRight2Func = e => {
        const { swipeRight2Func = () => {} } = this.props;
        swipeRight2Func(e);
        this.closeSwipMenu(e);
    }

    refreshElement = async e => {
        const { refreshElement = () => {} } = this.props;
        this.setState({ refreshElementInProgress: true });
        await refreshElement(e);
        this.setState({ refreshElementInProgress: false });
        this.closeSwipMenu(e);
    }

    handleIntersection = (entries) => {
        const { holdingStore } = this.props;
        if (holdingStore) {
            holdingStore.intersectionObserverCallback(entries);
        }
    }

    clearSearch = () => {
        this.setState({
            search: '',
        });
    }

    searchInput = (e) => {
        const search = e.target.value;
        const { holdingStore, searchCallback = () => {} } = this.props;
        this.setState({ search });
        searchCallback(search);
    }

    componentDidMount() {
        // this.setLoadMore();
        const { holdingStore } = this.props;
        if (holdingStore) {
            this.scrollViewTimer = setInterval(() => holdingStore.postScrollview(), 30 * 1000);
        }
    }
    componentWillUnmount() {
        // this.unsetLoadMore();
        const { holdingStore } = this.props;
        if (holdingStore) {
            holdingStore.postScrollview()
            clearInterval(this.scrollViewTimer);
        }
    }

    renderContent = ({ obj, idx }) => {
        const {
            title = '',
            list = [],
            classNames = '',
            qty,
            elHeader,
            elIcon,
            elTitle = e => e.title,
            elLink,
            elImageStatus = e => '',
            elDescription = e => '',
            elInfo1 = e => '',
            elInfo2 = e => '',
            elInfo3 = e => '',
            viewTags,
            inc,
            dec,
            remove,
            editQty,
            wrapperProps = {},
            wrapperPropsFunc = () => {},
            deleteElement,
            rentOutElement,
            moveElement,
            checkElement,
            addUsageData,
            editElement,

            swipeRight1Icon,
            swipeRight1Class = 'secondary',
            swipeRight1Title,
            swipeRight1Func,

            swipeRight2Icon,
            swipeRight2Class = 'secondary',
            swipeRight2Title,
            swipeRight2Func,

            refreshElement,
            showCheckElement = () => true,
            showIncDec = () => true,
            showEmptyImage = true,
            showCevron = false,
            showRightIcon,
            rightBoxField = '',
            rightBoxFieldValue = e => e,
            rightBoxFieldPostfix = '',
            rightBoxFieldPostfixField,
            rightBoxFieldPostfixValue,
            rightBoxFieldBottomValue,

            rightBoxTitle,
            rightBoxValue = () => '',
            rightBoxClass = () => '',

            rightFunc,

            imgHeight,
            minHeight = '60px',
            maxHeight = '60px',

            holdingStore,
        } = this.props;
        const { appState } = this.props.stores;
        const { opts = {} } = appState;

        const { absDiffX, swipeId, swipeDirection, refreshElementInProgress, focusedIndex, focusStarted } = this.state;
        const swipeMenuIsOpen = absDiffX > 0;

        const hasSwipeLeftFunctions = (deleteElement || rentOutElement || moveElement || checkElement || addUsageData);
        const hasSwipeRightFunctions = (editElement || swipeRight1Func || swipeRight2Func);
        const hasSwipeFunctions = (hasSwipeLeftFunctions || hasSwipeRightFunctions);

        const hasVideoStream = obj.files?.some(file => file.s3LinkStream);
        const videoStreamPreview = obj.files?.find(file => file.s3LinkStream)?.s3LinkStreamPreview1;

        let isElementSwipedOpen = false;
        if (swipeDirection === 'left' && opts.swipeLeft) {
            isElementSwipedOpen = (
                swipeId === obj.id &&
                swipeMenuIsOpen &&
                hasSwipeLeftFunctions
            );
        } else if (swipeDirection === 'right' && opts.swipeRight) {
            isElementSwipedOpen = (
                swipeId === obj.id &&
                swipeMenuIsOpen &&
                hasSwipeRightFunctions
            );
        }
        return (<>
            <div
                class={`w-100 d-flex flex-row position-relative border-right`}
                style={`
                    min-height: ${minHeight};
                    max-height: ${maxHeight};
                    height: ${maxHeight};
                `}
            >
                <div
                    class={`w-100 flex-fill d-flex flex-row justify-content-between box-line overflow-hidden position-relative ${obj.classNames || ''} ${(focusStarted && focusedIndex === idx) ? 'box-line-focused' : ''}`}
                    onClick={obj.viewElement || this.viewElement}
                    data-isopen={swipeMenuIsOpen}
                    data-id={obj.id}
                    data-title={elTitle(obj)}
                    data-type={holdingStore?.name}
                    {...wrapperProps}
                    {...wrapperPropsFunc(obj)}
                    // onTouchstart={hasSwipeFunctions && this.touchStart}
                    // onTouchend={hasSwipeFunctions && this.touchEnd}
                    // onTouchmove={hasSwipeFunctions && this.touchMove}
                    style={`
                        ${obj.styles || ''}
                        ${isElementSwipedOpen && `
                            overflow: hidden;
                            left: ${swipeDirection === 'left' ? `-` : ''}${absDiffX || 0}px !important;
                        `}
                    `}
                >
                    {/* swipeIdOpen: {swipeIdOpen} */}
                    {/* swipeId: {swipeId} */}
                    {/* diffX: {diffX} */}
                    {/* isSwipedOpen: {JSON.stringify(isSwipedOpen, null, 4)} */}
                    {/* isSwipedOpen: {JSON.stringify({ diffX, isSwipedOpen, swipeId}, null, 4)} */}
                    {elHeader && <div class='box-line-header position-absolute box-line-info' style='top: -2px; right: 110px;'>
                        {elHeader(obj)}
                    </div>}
                    {qty ? <>
                        <div class='box-line-qty text-right'>{qty(obj)}</div>
                    </> : <>
                        {util.isDefined(obj.qty) && <div
                            class='box-line-qty text-right'
                            onClick={opts.allowEditQty ? editQty : () => {}}
                            data-id={obj.id}
                            data-qty={obj.qty}
                        >
                            {obj.qty}
                        </div>}
                    </>}
                    {obj.icon && <>
                        <div class='box-line-icon mt-2 ml-3' style='font-size: 1.7em; min-width: 25px; width: 25px;'>
                            <i class={obj.icon} />
                        </div>
                    </>}
                    {elIcon && <>
                        <div class='d-flex flex-row justify-content-center align-items-center box-line-icon mt-2 ml-3' style='font-size: 1.7em; min-width: 25px; width: 25px;'>
                            <i class={elIcon(obj)} />
                        </div>
                    </>}
                    <div class='flex-fill overflow-hidden d-flex flex-column justify-content-center' style='font-size: 0.9em; line-height: 1.2em;'>
                        <div class='w-100 d-flex flex-row justify-content-start align-items-center pl-3 position-relative'>
                            <span class='font-weight-bold'>
                                {elLink ? <>
                                    <a href={elLink(obj)} class='text-dark'>
                                        {elTitle(obj)}
                                    </a>
                                </> : <>
                                    {elTitle(obj)}
                                </>}
                            </span>
                            {elDescription(obj) && <span class='ml-1'>
                                {elDescription(obj)}
                            </span>}
                        </div>
                        <div
                            class='box-line-info d-flex flex-row justify-content-between px-3 pb-0 position-relative'
                            style='font-size: 0.8em; line-height: 1.2em;'
                        >
                            {/* <GradientRight stores={this.props.stores} /> */}
                            <div class='box-line-info-item'>
                                {elInfo1(obj)}
                            </div>
                            <div class='box-line-info-item'>
                                {elInfo2(obj)}
                            </div>
                            <div class='box-line-info-item'>
                                {elInfo3(obj)}
                            </div>
                        </div>
                        <div class='box-line-info d-flex flex-row justify-content-between px-3 pb-0 position-relative'>
                            {/* <GradientRight stores={this.props.stores} /> */}
                            {viewTags && <>
                                {viewTags(obj)}
                            </>}
                        </div>
                    </div>
                    {!!obj[rightBoxField] && rightBoxFieldValue(obj[rightBoxField]) && <>
                        <div
                            class='box-line-right-box mt-1 mb-1 mr-2 py-1 px-1 text-muted bg-light rounded d-flex flex-column justify-content-center align-items-center flex-wrap text-center'
                            style='width: 75px !important; min-width: 75px !important; line-height: 1.0em;'
                        >
                            <nobr><span class='font-weight-bold'>{rightBoxFieldValue(obj[rightBoxField])}</span></nobr>
                            <small class='text-muted'>{rightBoxFieldPostfixValue ? rightBoxFieldPostfixValue(obj[rightBoxFieldPostfixField]) : rightBoxFieldPostfix}</small>
                            {rightBoxFieldBottomValue && <>
                                <small class='text-muted font-weight-lighter'>{rightBoxFieldBottomValue(obj)}</small>
                            </>}
                        </div>
                    </>}
                    {rightBoxTitle && <>
                        <div
                            class={`box-line-right-box mt-1 mb-1 mr-2 py-1  rounded d-flex flex-column justify-content-center align-items-center flex-wrap text-center
                                ${rightBoxClass && rightBoxClass(obj) ? rightBoxClass(obj) : 'text-muted bg-light'}`}
                            style='width: 60px; line-height: 1.0em;'
                        >
                            <span class='font-weight-bold'>{rightBoxValue(obj)}</span>
                            <small>{rightBoxTitle}</small>
                        </div>
                    </>}
                    {rightFunc && <>
                        {rightFunc(obj)}
                    </>}
                    {obj.images && (showEmptyImage || obj.images[0] || obj.imageUrl) && <div class='box-line-image position-relative' style={`${imgHeight ? `height: ${imgHeight} !important;` : ''}`}>
                        {obj.images[0] ? <>
                            {elLink ? <>
                                <a href={elLink(obj)} class='text-dark'>
                                    <img src={`${obj.images[0].s3MediumLink}`} class='rounded' style={`${imgHeight ? `height: ${imgHeight} !important;` : ''}`} />
                                </a>
                            </> : <>
                                <img src={`${obj.images[0].s3MediumLink}`} class='rounded' style={`${imgHeight ? `height: ${imgHeight} !important;` : ''}`} />
                            </>}
                        </> : <>
                            {obj.imageUrl ? <>
                                <img src={`${obj.imageUrl}`} class='rounded' style={`${imgHeight ? `height: ${imgHeight} !important;` : ''}`} />
                            </> : <>
                                <div
                                    class='box-line-image-placeholder border h-100 rounded d-flex justify-content-center align-items-center text-muted'
                                    style={`
                                        font-size: 2.0em;
                                        ${videoStreamPreview ? '' : 'opacity: 0.6;'}
                                        background-image: url(${videoStreamPreview});
                                        background-size: cover;
                                        background-position: center;
                                        background-repeat: no-repeat;
                                    `}
                                >
                                    {hasVideoStream ? <>
                                        <i class='fa-solid fa-circle-play' />
                                    </> : <>
                                        <i class='fa-duotone fa-camera-retro' />
                                    </>}
                                </div>
                            </>}
                        </>}
                        {opts.allowEditQty && showIncDec(obj) && inc && <>
                            <button type='button' class='btn btn-lg btn-success px-1 py-0 position-absolute rounded-pill-both' style='font-size: 1.4em; bottom: 0px; right: 0px;' onClick={inc} data-id={obj.id} data-qty={obj.qty}><i class='fa-solid fa-plus' /></button>
                        </>}
                        {opts.allowEditQty && showIncDec(obj) && dec && <>
                            <button type='button' class='btn btn-lg btn-danger px-1 py-0 position-absolute rounded-pill-both' style='font-size: 1.4em;bottom: 0px; left: 0px;' onClick={dec} data-id={obj.id} data-qty={obj.qty}><i class='fa-solid fa-minus' /></button>
                        </>}
                    </div>}

                    {showCevron && <>
                        <div class='box-line-chevron mt-3 mr-3 text-muted'>
                            <i class='fa-solid fa-chevron-right' />
                        </div>
                    </>}
                    {showRightIcon && <>
                        <div class='box-line-icon mt-3 mr-3'>
                            <i class={showRightIcon(obj)} />
                        </div>
                    </>}
                    {/* {remove && <>
                        <button type='button' class='btn btn-lg btn-link' style='font-size: 1.5em;' onClick={remove} data-id={obj.id}><i class='fa-solid fa-trash' /></button>
                    </>} */}
                </div>
            </div>
        </>);
    }

    searchFilter = (el) => {
        const { search } = this.state;

        const searchFields = ['name', 'title', 'firstname', 'lastname', 'sportsClub',
        'email', 'phone', 'address', 'city', 'zip', 'country', 'description', 'tags'];
        if (!search) {
            return true;
        }
        const searchLower = search.toLowerCase();
        for (let i = 0; i < searchFields.length; i += 1) {
            const field = searchFields[i];
            if (el[field] && `${el[field]}`.toLowerCase().includes(searchLower)) {
                return true;
            }
        }
        return false;
    }

    render() {
        const { search, focusedIndex } = this.state;
        const { appState, userStore } = this.props.stores;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const {
            title = '',
            list = [],
            classNames = '',
            helpTextClassNames = '',
            hideEmpty = false,
            addItem,
            filter,
            sortTitle = '',
            sortBy = [],
            sortFunc = () => {},
            showFilterBox = true,
            toggle,
            toggleTitle,
            toggleFunc,
            help,
            holdingStore,
            width = '300px',
            minHeight = '60px',
            maxHeight = '60px',
        } = this.props;

        if (hideEmpty && list.length === 0) {
            return (<></>);
        }
        // if (list.length === 0) {
        //     return (<>
        //         <Placeholder
        //             count={3}
        //             height={minHeight}
        //             style={{
        //                 width: width,
        //                 minHeight: minHeight,
        //                 maxHeight: maxHeight,
        //                 height: maxHeight,
        //             }}
        //         />
        //     </>);
        // }

        let finalList = Array.isArray(list) ? list.filter(this.searchFilter) : [];

        if (sortBy.length > 0) {
            finalList = finalList.sort(fieldSorter([sortTitle]));
        }

        const sortByField = sortTitle.replace(/^-/, '');
        const sortByFieldObj = fields.getField('part', sortByField);

        const sortDir = sortTitle[0] === '-' ? 'desc' : 'asc';

        return (<>
            <div
                class={`w-100 d-flex flex-column ${classNames}`}
                style={`
                    min-height: ${minHeight};
                    max-height: ${maxHeight};
                    height: ${maxHeight};
                `}
            >
                <div class={`d-flex flex-column rounded ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                    {list.length === 0 && <>
                        <div class='w-100 d-flex flex-column px-4 py-3 box-line'>
                            <div class='w-100 d-flex flex-row justify-content-center'>
                                <div class='box-line-add'>
                                    <i class='fa-solid fa-circle-question' />
                                </div>
                            </div>
                        </div>
                    </>}

                    <div
                        class='d-flex flex-row w-100 overflow-auto no-scrollbar'
                    >
                        <FastList
                            data={finalList}
                            containerWrapperClassNames={'d-flex flex-row'}
                            wrapperClassNames={'overflow-hidden box-line'}
                            wrapperStyles={`min-width: ${width}; max-width: ${width}; width: ${width};`}
                            contentClassNames={`w-100 d-flex flex-row justify-content-between `}
                            renderContent={this.renderContent}
                            dataFields={['id', 'type']}
                            dataValues={{ type: holdingStore.name }}
                            handleIntersection={this.handleIntersection}
                        />
                    </div>

                </div>
            </div>
            {help && <>
                <div
                    class={`w-100 d-flex justify-content-center px-3 text-center ${helpTextClassNames}`}
                    style='line-height: 1.0em;'
                >
                    <small class='text-muted'>
                        {help}
                    </small>
                </div>
            </>}
        </>);
    }
}

export default GuiListTicker;
