import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import ImageScroller from '../../components/imagescroller';
import ClipboardCopy from '../../components/gui/clipboardCopy';
import GoogleSheet from '../../components/google/sheet';
import Placeholder from '../../components/gui/placeholder';
import MarkdownDisplay from '../../components/gui/markdownDisplay';
import PlaceholderTable from '../../components/gui/placeholderTable';

import VideoStreamingPlayer from '../../routes/videostreaming/player';

import localUtil from '../../lib/util';

function partImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3LargeLink}`;
    return imgSrc;
}

@observer
class ArticleView extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { id, uuidv4, all } = props;
        const { articleStore, appState } = this.props.stores;
        if (id) {
            articleStore.updateKeyValue('article', {});
            articleStore.updateKeyValue('googleSheet', {});

            const query = { hostname: articleStore.currentHostname };
            if (all) {
                query.all = 1;
            }
            await articleStore.load(id, false, { addData: ['tags'], query });
            const { article } = articleStore;
            if (article.googleSheetId) {
                articleStore.getGoogleSheet(article.googleSheetId);
            }
        }
        if (uuidv4) {
            articleStore.updateKeyValue('article', {});
            const articles = await articleStore.load({
                addData: ['tags'],
                query: {
                    uuidv4,
                    hostname: articleStore.currentHostname,
                },
                skipUpdate: true,
            });
            articleStore.updateKeyValue('article', articles[0]);
            appState.updateMetaTags(articles[0]);
            const { article } = articleStore;
            if (article.googleSheetId) {
                articleStore.getGoogleSheet(article.googleSheetId);
            }
        }
    }

    viewTags = obj => {
        if (obj.tags?.length === 0 && obj.loans?.length === 0 && !obj.ownedByOther && !obj.url && !obj.parents?.length > 0 && !obj.files?.length > 0) {
            return '';
        }
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tagClass = darkmode ? 'dark' : 'light';

        return (<>
            <div class='w-100 overflow-hidden'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {obj.files?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-file' /> <span class='font-weight-lighter'> x {obj.files.length}</span>
                        </span>
                    </>}
                    {obj.tags?.length > 0 && obj.tags.map(tag => {
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    closeAllDrawers = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawer();
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {
        const { appState } = this.props.stores;
        appState.restoreDefaultMetaTags();
    }

    render() {
        const {
            all,
        } = this.props;
        const { appState, userStore, articleStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { article, googleSheet } = articleStore;

        const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

        return (<>
            <div class='w-100 d-flex justify-content-center position-relative'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 0px; padding-bottom: 100px;'>
                   {/* {isAdmin && <div class={`position-absolute`} style={`top: 20px; right: 50px; z-index: 100000;`}>
                        <button onClick={this.editArticle} class={`btn btn-sm btn-outline-secondary rounded-pill mr-2`}>
                            <i class={`fa-regular fa-pen mr-2`} />
                            <Text id='article.edit'>Edit article</Text>
                        </button>
                    </div>} */}
                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class='d-flex flex-column'>
                                {/* {JSON.stringify(part)} */}
                                {article.files?.length > 0 && article.files.map(file => {
                                    const poster = file.s3LinkStreamPreview1;
                                    // console.log({ file });
                                    if (file.s3LinkStream) {
                                        return (<>
                                            <div class='video-container rounded-lg'>
                                                <VideoStreamingPlayer
                                                    key={file.s3LinkStream}
                                                    videoStreamUrl={file.s3LinkStream}
                                                    stores={this.props.stores}
                                                    poster={poster}
                                                />
                                            </div>
                                        </>);
                                    }
                                    if (file.s3Link.match(/\.(m4v|m4a|mp4)$/i)) {
                                        return (<>
                                            <div className='video-container rounded-lg'>
                                                <video controls className='video-player'>
                                                    <source src={file.s3Link} type='video/mp4' />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        </>);
                                    }
                                    if (file.s3Link.match(/\.(mov)$/i)) {
                                        return (<>
                                            <div className='video-container rounded-lg'>
                                                <video controls className='video-player'>
                                                    <source src={file.s3Link} type='video/quicktime' />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                            <div class='video-info d-flex flex-row justify-content-center'>
                                                <span class='text-muted'>size:</span> {localUtil.formatFileSize(file.size)}
                                                <span class='text-muted ml-3 mr-1'>encoding:</span> {file.encoding}
                                                <span class='text-muted ml-3 mr-1'>mimetype:</span> {file.mimetype}
                                                <span class='text-muted ml-3 mr-1'>ext:</span> {file.ext}
                                                <span class='text-muted ml-3 mr-1'>s3Link:</span> <a href={file.s3Link} target='_blank' rel='noopener noreferrer'>download</a>
                                            </div>
                                        </>);
                                    }
                                })}
                                <ImageScroller
                                    images={article.images}
                                    stores={this.props.stores}
                                    showImg={img => partImg(img, this.props, '1920x')}
                                    maxHeight={'80vh'}
                                    // imageContainerStyles={'aspect-ratio: 4 / 3'}
                                    imageStyles={'aspect-ratio: 4 / 3'}
                                    rounded={false}
                                    // maxWidth={maxWidth}
                                />
                            </div>
                            <div class={`d-flex flex-column mx-3 mt-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                <div>
                                    <span
                                        class='mb-0 mt-0 py-1 px-0'
                                        style={`font-size: clamp(0.8rem, 5vw, 1.2rem); font-weight: 700; line-height: 1.2em; text-transform: uppercase;`}
                                    >
                                        {article.headingIcon ? <i class={`${article.headingIcon} mr-2`} /> : ''}
                                        {article.heading}
                                    </span>
                                </div>
                                <div>
                                    <h1 style={`font-size: clamp(1.5rem, 5vw, 4rem);`}>{article.title}</h1>
                                </div>
                                <div class='font-weight-lighter'>
                                    {util.formatDate(article.publishDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                </div>
                                <div class='d-flex flex-column mb-3 font-weight-bold'>
                                    <MarkdownDisplay markdown={localUtil.replaceImages(article.ingress, article.images, language, false)} markedOpts={localUtil.getMarkdownOptions()} />
                                </div>
                                <div class='d-flex flex-column'>
                                    <MarkdownDisplay markdown={localUtil.replaceImages(article.body, article.images, language, false)} markedOpts={localUtil.getMarkdownOptions()} />
                                </div>

                                {article.googleSheetId && <>
                                    {googleSheet && googleSheet?.title ? <>
                                        <div class='d-flex flex-column my-3'>
                                            <GoogleSheet
                                                doc={googleSheet}
                                                stores={this.props.stores}
                                            />
                                        </div>
                                    </> : <>
                                        <div class='d-flex flex-column justify-content-center align-items-center my-3'>
                                            <span class='text-muted'>
                                                <Text id='article.googlesheet-loading'>Google sheet loading...</Text>
                                            </span>
                                            <div class='spinner-border text-primary' role='status'>
                                                <span class='sr-only'>Loading...</span>
                                            </div>
                                        </div>
                                        <PlaceholderTable rows={10} columns={5} />
                                        {/* <Placeholder count={5} style={{ width: '100%', height: '20px' }} /> */}
                                    </>}
                                    {/* <xmp>{JSON.stringify(googleSheet, null, 2)}</xmp> */}

                                </>}
                            </div>

                            {article.files && article.files.length > 0 && <>
                                <div class={`d-flex flex-column mx-3 mt-4 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                    <div class='d-flex flex-column'>
                                        {article.files.map(file => {
                                            return (<>
                                                <a href={file.s3Link} class='badge badge-pill badge-light border mr-2' target='_blank' rel='noopener noreferrer'>{file.name} <span class='font-weight-lighter'>({localUtil.formatFileSize(file.size)})</span></a>
                                            </>);
                                        })}
                                    </div>
                                </div>
                            </>}

                            {article.tags && article.tags.length > 0 && <div class={`d-flex flex-column mx-3 mt-4 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                {this.viewTags(article)}
                            </div>}

                            <div class={`d-flex flex-row justify-content-center mx-3 mt-4 rounded-lg px-4 py-3`}>
                                {/* <small>
                                    <ClipboardCopy
                                        label={<><i class='fa-duotone fa-link' /> <Text id='article.link'>Link to article</Text></>}
                                        text={`${window.location.protocol}//${window.location?.hostname}${window.location.port ? `:${window.location.port}` : ''}/#/article/${encodeURIComponent(article.title).toLocaleLowerCase()}/${article.uuidv4}`}
                                    />
                                </small> */}
                                <small class=''>
                                    <ClipboardCopy
                                        label={<><i class='fa-duotone fa-share' /> <Text id='article.share'>Share article</Text></>}
                                        text={`${window.location.protocol}//${window.location?.hostname}${window.location.port ? `:${window.location.port}` : ''}/share/article/${article.uuidv4}?domain=${window.location?.hostname}${window.location.port ? `:${window.location.port}` : ''}`}
                                    />

                                    {/* <a href={`/article/${encodeURIComponent(article.title).toLocaleLowerCase()}/${article.uuidv4}`} onClick={this.closeAllDrawers}>
                                        <i class='fa-duotone fa-link' /> <Text id='article.link'>Link to article</Text>
                                    </a> */}
                                </small>
                            </div>
                        </div>
                    </div>

                    {/* <SimpleBlog stores={this.props.stores} {...this.props} /> */}
                </div>
            </div>
        </>);
    }
}

export default ArticleView;
