import { h, Component } from 'preact';
import { Link } from 'preact-router/match';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import linkState from 'linkstate';
import { route } from 'preact-router';
import { Text, Localizer, withText } from 'preact-i18n';

import Logo from '../../components/logo';
import LogoCustomerApplicationForm from '../../components/logo/customerApplicationForm';
import LogoCustomer from '../../components/logo/customer';

import localUtil from '../../lib/util';

import style from './style.css';

const initialState = {
    menu: {
		profile: false,
		main: false,
	},
	searchText: '',
};

const MAX_HEIGHT = '25px';

@withText(props => ({
    allVisitsTitle: <Text id='header.all-visits-title'>All visits</Text>,
    reportsTitle: <Text id='header.reports-title'>Reports</Text>,
    journalsTitle: <Text id='header.journals-title'>Journals</Text>,
    searchTitle: <Text id='header.search-title'>Search</Text>,
}))
@observer
class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

	toggleDarkmode = (e) => {
        const { appState } = this.props.stores;
		appState.toggleDarkmode();
	}

	reloadApp = () => {
		window.location.reload(true);
	}

	changeLanguage = (e) => {
		const lang = e.target.value;
		const { userStore } = this.props.stores;
		const { user } = userStore;
		userStore.setLanguage({ email: user.email, language: lang });
	}

	scrollToTop = () => {
		const {  refreshPage = () => {} } = this.props;
		const { appState } = this.props.stores;
		appState.appContainerScrollTop();
		refreshPage();
	}

	addContent = () => {
		const { appState } = this.props.stores;
        const { drawerHeightXSmall } = appState;
        appState.openDrawer('addContent', {
            height: drawerHeightXSmall,
        }, 1);
	}

    search = () => {
		const { appState } = this.props.stores;
        const { drawerHeightLarge } = appState;
        appState.openDrawerRight('search', {
            height: drawerHeightLarge,
			title: 'Search',
        }, 1);
	}

	editApplicationOffer = e => {
        // return;
        const { id, title } = this.props;
        const { drawerLevel = 1 } = this.props;
		const { appState, applicationOfferStore } = this.props.stores;
		const { applicationOffer } = applicationOfferStore;
		const { drawerHeightMedium } = appState;

		appState.openDrawer('editApplicationOffer', {
            id: applicationOffer.id,
			height: drawerHeightMedium,
            callback: this.closeAddMedia,
		}, drawerLevel + 2);
	}

	userMenu = () => {
		const { appState, userStore } = this.props.stores;
		const { drawerHeightMedium } = appState;

		const { notifications = [] } = userStore;
		const unreadNotificatins = notifications.filter(e => !e.read);
		const unread = unreadNotificatins.length || 0;
        appState.openDrawer('menu', {
            height: drawerHeightMedium,
        }, 1);
	}

	gotoFrontpage = () => {
		route('/');
	}

	goBack = () => {
		window.history.back();
	}

	goToPage = (url) => {
		route(url);
	}

	onClickAllVisits = e => {
		const { drawerLevel = 1, allVisitsTitle } = this.props;
		const { appState } = this.props.stores;

		appState.openDrawerRight('allVisits', {
            hideAdmin: false,
			title: allVisitsTitle,
		}, drawerLevel + 1);
	}

	onClickCalendar = e => {
		route('/calendar/');
	}

	onClickNotifications = e => {
		route('/calendar/notifications');
	}

	onClickReports = e => {
		const { drawerLevel = 1, reportsTitle } = this.props;
		const { appState } = this.props.stores;

		appState.openDrawerRight('reports', {
            hideAdmin: false,
			title: reportsTitle,
		}, drawerLevel + 1);
	}

	onClickJournals = e => {
		const { drawerLevel = 1, journalsTitle } = this.props;
		const { appState } = this.props.stores;

		appState.openDrawerRight('journals', {
            hideAdmin: false,
			title: journalsTitle,
		}, drawerLevel + 1);
	}

	renderLeftButton = () => {
		const { appState, userStore, applicationOfferStore } = this.props.stores;
		const { user } = userStore;
		const { isApproved } = user;
        const { path, opts, isDevelopment } = appState;

		const darkmode= util.getNestedValue(user, 'settings.darkmode');
		const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;
		if (path.match(/^\/applicationoffer\/\d+/)) {
			const { customerid } = applicationOfferStore;
			let url = `/applicationoffer/`;
			if (customerid) {
				url = `/applicationoffer/customer/${customerid}`;
			}

			return (<>
				<span onClick={() => url ? this.goToPage(url) : this.goBack()} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`fa-regular fa-arrow-left`} /> <span class='d-none d-md-inline-block ml-1'><Text id='header.back'>Back</Text></span>
				</span>
			</>);
		}

		return (<>
			&nbsp;
			{/* <span onClick={this.addContent} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
				<i class={`fa-regular fa-square-plus`} />
			</span> */}
			{/* {path} */}
		</>);
	}

	renderRightButton = () => {
		const { appState, userStore } = this.props.stores;
		const { user, isAdmin } = userStore;
		const { isApproved, isSoknadsguideSuperAdmin } = user;
        const { path, opts = {} } = appState;
		const darkmode= util.getNestedValue(user, 'settings.darkmode');
		const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

		return (<>
			{(isAdmin || isSoknadsguideSuperAdmin) && path.match(/^\/applicationoffer\/\d+/) && <>
				<span onClick={this.editApplicationOffer} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`fa-solid fa-pen ml-3 mr-1`} />
				</span>
			</>}
			{isApproved && <>
				<span onClick={this.search} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
					<i class={`${path.match(/\/search/) ? 'fa-solid fa-magnifying-glass' : 'fa-regular fa-magnifying-glass'} mr-1 ml-3`} />
				</span>
			</>}
			<span onClick={this.userMenu} class={`islink text-primary d-flex align-items-center ${linkTextClass}`} style={`${linkTextStyle}`}>
				<i class={`fa-regular fa-bars mr-1 ml-3`} />
			</span>
			{/* {path} */}
		</>);
	}

    render() {
		const { menu, searchText } = this.state;
		const { scrolledDown } = this.props;
        const { appState, userStore, applicationOfferStore } = this.props.stores;
        const { viewmode, path, opts = {}, isDevelopment, currentCustomer } = appState;
		const { allUsers, isAdmin, user, customer, notifications = [], currentEmail, simulatedEmail, simulatedCellphone } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

		const image = localUtil.getImage({ user, customer });
		const displayNameShort = localUtil.displayNameShort(user);
		const displayName = localUtil.displayName(user);

		const unreadNotificatins = notifications.filter(e => !e.read);
		const unread = unreadNotificatins.length || 0;
		const { language = 'en', toggleFeature = {}, hasValidSubscription } = user;
		const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

		// Find servername and display it
		const serverName = window.location.hostname;
		let version = serverName.match(/(dev)/) ? 'beta' : null;
		// version = serverName.match(/(localhost|127.0.0.1)/) ? 'dev' : null;

		const isApplicationOffer = /^\/applicationoffer\/(\d+|customer)/.test(path);
		const hasCustomerLogo = opts.showCustomerLogo && currentCustomer?.id && currentCustomer?.logoHeight;
		const hasApplicationHeader = isApplicationOffer;
		const { customerid, customerLogoHeight = 0 } = applicationOfferStore;

		return (<>
			{hasApplicationHeader && <div class={`fixed-top w-100 d-flex flex-row justify-content-center ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-3 py-2 border-bottom overflow-hidden`} style={`height: ${customerLogoHeight}px;`}>
				<LogoCustomerApplicationForm stores={this.props.stores} customerid={customerid} logoHeight={customerLogoHeight} />
			</div>}

			<nav
				class={`navbar navbar-expand-xl navbar-themed ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} fixed-top py-0 ${scrolledDown > 0 ? style.scrolledDown : style.scrolledUp}`}
				style={`
					height: ${hasCustomerLogo ? currentCustomer?.logoHeight : '45px'};
					${hasApplicationHeader ? `top: ${customerLogoHeight}px;` : ''}
					${hasCustomerLogo && currentCustomer?.backgroundColorHeader ? `background-color: ${currentCustomer.backgroundColorHeader} !important;` : ''}
				`}
			>
				<div class='d-flex justify-content-between align-items-center w-100'>

					<div class={`${opts.showCustomerLogo ? 'w-25' : 'w-25'} d-flex justify-content-start align-items-center`}>
						{this.renderLeftButton()}
					</div>

					<div class='flex-fill d-flex flex-row justify-content-center align-items-center'>
						{opts.showCustomerLogo && currentCustomer?.id && currentCustomer.logoHeight ? <>
							<LogoCustomer stores={this.props.stores} customerid={customerid} logoHeight={currentCustomer.logoHeight || '45px'} />
						</> : <>
							<Logo darkmode={darkmode} stores={this.props.stores} style={'font-size: 1.2em;'} />
							{version && <div
								class='position-absolute'
								style='
									top: 21px;
									left: calc(50% + 60px);
									font-size: 12px;
									color: red;
									font-weight: 700;
								'
							>
								{version}
							</div>}
						</>}
					</div>

					<div class='w-25 d-flex justify-content-end align-items-center'>
						{this.renderRightButton()}
					</div>

					{/* <div>
						<span class='mt-2 ml-4 position-relative' >
							<a href='/users/notifications' class={`islink text-primary ${linkTextClass}`} style={`${linkTextStyle}`}>
								<i class={`${path.match(/\/users\/notifications/) ? 'fa-solid fa-bell' : 'fa-regular fa-bell'} mr-1`} />
								{unread > 0 && <>
									<span
										class='badge badge-pill badge-danger position-absolute'
										style='font-size: 0.5em; right: -5px; top: -4px; padding: 2px 5px 3px;'
									>{unread > 9 ? '9+' : unread}</span>
								</>}
							</a>
						</span>
					</div> */}

				</div>

				{isAdmin && simulatedEmail && <div class='position-absolute badge badge-danger' style='top: 0px; left: 250px;'>
					<a href='/admin/user' class='text-white'>
						<span class='font-weight-lighter'>Simulating:</span> {user.firstname} {user.lastname} ({simulatedEmail})
					</a>
				</div>}
				{isAdmin && simulatedCellphone && <div class='position-absolute badge badge-danger' style='top: 0px; left: 250px;'>
					<a href='/admin/user' class='text-white'>
						<span class='font-weight-lighter'>Simulating:</span> {user.firstname} {user.lastname} ({simulatedCellphone})
					</a>
				</div>}

				{user.id && <div class='position-absolute' style='top: 0px; left: 5px; font-size: 0.5em;'>
					<span class='font-weight-lighter'>Bruker: #{user.id}</span> {user.firstname} {user.lastname} / <span class='font-weight-lighter'>{user.cellphone}</span>
					{hasValidSubscription && <>
						<span class='font-weight-normal bg-danger rounded-pill text-white px-2 ml-2'>
							<i class='fa-solid fa-face-grin-wide'></i> Insider
						</span>
					</>}
				</div>}

			</nav>
			{/* <xmp>{JSON.stringify(this.props, null, 2)}</xmp> */}
		</>);
	}
}

export default Navigation;
