import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import ImageScroller from '../../components/imagescroller';

function customerImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

@observer
class Customer extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { id } = props;
        const { customerStore } = this.props.stores;
        customerStore.updateKeyValue('customer', {});
        await customerStore.load(id, false, { addData: ['members'] });
    }

    editCustomer = () => {
		const { id, drawerLevel } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('editCustomer', {
			height: drawerHeightMedium,
			id,
		}, drawerLevel + 1);
	}

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            page,
            artid,
            bib,
            showAnimations = true,
        } = this.props;
        const { sessionid, showMenu } = this.state;
        const { appState, userStore, customerStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { customer } = customerStore;

        const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    {isAdmin && <div class={`d-flex justify-content-end flex-row w-100 pr-3 mb-1`}>
                        <button onClick={this.editCustomer} class={`btn btn-sm btn-outline-secondary rounded-pill mr-2`}>
                            <i class={`fa-regular fa-pen mr-2`} />
                            <Text id='customer.edit'>Edit customer</Text>
                        </button>
                    </div>}

                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class='d-flex'>
                                {/* {JSON.stringify(customer)} */}
                                <ImageScroller images={customer.images} stores={this.props.stores} showImg={img => customerImg(img, this.props, '1920x')} />
                            </div>
                            <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                <div class='d-flex flex-row'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='customer.customer-number'>customer Number</Text>
                                    </span>
                                    <span class='ml-2'>{customer.customerNumber}</span>
                                </div>
                                <div class='d-flex flex-row'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='customer.description'>Description</Text>
                                    </span>
                                    <span class='ml-2'>{customer.description}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <SimpleBlog stores={this.props.stores} {...this.props} /> */}
                </div>
            </div>
        </>);
    }
}

export default Customer;
