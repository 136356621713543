import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import ImageScroller from '../../components/imagescroller';

import localUtil from '../../lib/util';

@observer
class GuiInfoBox extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {
            iconName,
            iconValue,
            iconPostfix,
            iconNameMiddle,
            iconNameBottom,
            name,
            nameMiddle,
            nameBottom,
            value,
            postfix,
            styles,
            classesContainer,
            classesBox,
        } = this.props;
        const { userStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class={`d-flex flex-column mx-1 my-1 ${classesContainer}`}>
                {(name || iconName) && <div class='d-flex flex-row justify-content-center align-items-center text-center'>
                    <small>
                        <small class='font-weight-light'>
                            {iconName && <>
                                <i class={`${iconName} mr-2`} />
                            </>}
                            {name}
                        </small>
                    </small>
                </div>}
                <div
                    class={`box-container d-flex flex-column justify-content-center align-items-center flex-wrap text-center py-3 px-4 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} ${classesBox}`}
                    style='line-height: 1.25em;'
                >
                    {(nameMiddle || iconNameMiddle) && <div class='d-flex flex-row flex-fill'>
                        <small class='font-weight-light'>
                            {iconNameMiddle && <>
                                <i class={`${iconNameMiddle} mr-2`} />
                            </>}
                            {nameMiddle}
                        </small>
                    </div>}

                    <div class='d-flex flex-row justify-content-center align-items-center'>
                        {iconValue && <>
                            <i class={`${iconValue} mr-2`} />
                        </>}
                        <span
                            style={`${styles ? styles : 'font-size: 1.2em;'}`}
                        >
                            {value}
                        </span>
                    </div>
                    {(postfix || iconPostfix) && <div class='d-flex flex-row justify-content-center align-items-center' style='opacity: 0.5;'>
                        {iconPostfix ? <>
                            <small class='text-muted'>
                                <i class={`${iconPostfix} ${postfix ? 'mr-2' : ''}`} />
                                {postfix}
                            </small>
                        </> : <>
                            <small class='text-muted'>
                                {postfix ? <>{postfix}</> : <>&nbsp;</>}
                            </small>
                        </>}
                    </div>}

                    {(nameBottom || iconNameBottom) && <div class='d-flex flex-row justify-content-center align-items-center text-center mt-2'>
                        <small class='font-weight-light'>
                            {iconNameBottom && <>
                                <i class={`${iconNameBottom} mr-2`} />
                            </>}
                            {nameBottom}
                        </small>
                    </div>}

                </div>
            </div>
        </>);
    }
}

export default GuiInfoBox;
