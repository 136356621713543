import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { route } from 'preact-router';
import linkState from 'linkstate';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';

@observer
class Logo extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    goHome = () => {
        const { appState } = this.props.stores;
        const { language = 'en', wantedPage = '/' } = appState;
        route(wantedPage);
    }

    scrollToTop = () => {
		const {  refreshPage = () => {} } = this.props;
		const { appState } = this.props.stores;
		appState.appContainerScrollTop();
		refreshPage();
	}

    render() {
        const { style = 'font-size: 2.1em;' } = this.props;
        const { appState, userStore, applicationOfferStore } = this.props.stores;
        const { path } = appState;
        const { isAdmin, user = {}, register = {} } = userStore;
        const { language = 'no' } = user;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const isApplicationOffer = /^\/applicationoffer\/(\d+|customer)/.test(path);
		const hasApplicationHeader = isApplicationOffer;
		const { customerid, customerLogoHeight = 0 } = applicationOfferStore;

        // CAREFUL: DO NOT CHANGE THIS LOGO FILE. IT WILL DISPLAY THE WRONG LOGO ON YOUR SITE.

        return (<>
            <a
                class='navbar-brand'
                href='/'
                data-path={path}
                onClick={this.scrollToTop}
            >
                {darkmode ? <>
                    <nobr>
                        <span style={style} onClick={this.goHome}>
                            {hasApplicationHeader ? <></> :
                                <img src='./assets/images/portalo_logo.png' class='navbar-brand' style='width: 125px; height: auto;'/>}
                        </span>
                    </nobr>
                </> : <>
                    <nobr>
                        <span style={style} onClick={this.goHome}>
                            {hasApplicationHeader ? <></> :
                                <img src='./assets/images/portalo_logo.png' class='navbar-brand' style='width: 125px; height: auto;'/>}
                        </span>
                    </nobr>
                </>}
                {/* <span class='font-weight-bold text-danger ml-1' style='font-size: 0.5em;'>
                    BETA
                </span> */}
            </a>
        </>);
    }
}

export default Logo;
