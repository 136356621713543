import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import md5 from 'crypto-js/md5';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import { app } from '../../../../backend/config/config-dist';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class NewSubmittedApplication extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('submittedApplication', null, ['adminStatus', 'adminComment']),
        };
    }

    loadAppOffer = async (props = this.props) => {
        const { id, applicationOfferId } = props;

        const { applicationOfferStore, submittedApplicationStore } = this.props.stores;

        const { submittedApplication } = submittedApplicationStore;

        submittedApplicationStore.updateKeyValue('appOffer', {});
        const appOffers = await applicationOfferStore.load(submittedApplication.applicationOffer || applicationOfferId, false, { skipUpdate: true });
        const appOffer = appOffers[0];

        submittedApplicationStore.updateKeyValue('appOffer', appOffer);
        try {
            if (appOffer.municipalities) {
                submittedApplicationStore.updateKeyValue('municipalities', JSON.parse(appOffer.municipalities));
            }
            if (appOffer.counties) {
                submittedApplicationStore.updateKeyValue('counties', JSON.parse(appOffer.counties));
            }
            if (appOffer.categories) {
                submittedApplicationStore.updateKeyValue('categories', JSON.parse(appOffer.categories));
            }
            if (appOffer.areas) {
                submittedApplicationStore.updateKeyValue('areas', JSON.parse(appOffer.areas));
            }
            if (appOffer.targetGroups) {
                submittedApplicationStore.updateKeyValue('targetGroups', JSON.parse(appOffer.targetGroups));
            }
            submittedApplicationStore.updateKeyValue('hideFields', appOffer.hideFields);
            submittedApplicationStore.updateKeyValue('hideSections', appOffer.hideSections);

            if (appOffer.fieldsOpts) {
                try {
                    const fOpts = {};
                    appOffer.fieldsOpts.forEach(field => {
                        const json = JSON.parse(field.json);
                        const keys = Object.keys(json);
                        keys.forEach(key => {
                            fOpts[key] = json[key];
                        });
                    });
                    submittedApplicationStore.updateKeyValue('fieldsOpts', fOpts);
                } catch (err) {
                    console.log('err', err);
                }
            }

            const toggleFieldValues = {};
            appOffer.hideFields.forEach(field => {
                toggleFieldValues[field.name] = 1;
            });
            const toggleSectionValues = {};
            appOffer.hideSections.forEach(section => {
                toggleSectionValues[section.key] = 1;
            });
            this.setState({
                toggleFieldValues,
                toggleSectionValues,
            });
        } catch (err) {
            console.log('err', err);
        }
        return appOffer;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id, applicationOfferId } = props;
        const { applicationOfferStore, submittedApplicationStore } = this.props.stores;
        if (emptyFirst) {
            submittedApplicationStore.updateKeyValue('submittedApplication', {});
        }
        if (id) {
            await submittedApplicationStore.load(id, false, { addData: ['tags'], query: {} });
        }
        const { submittedApplication } = submittedApplicationStore;

        const appOffer = await this.loadAppOffer();

        if (id) {
            if (emptyFirst) {
                submittedApplicationStore.updateKeyValue('submittedApplication', {});
            }
            await submittedApplicationStore.load(id, false, { addData: ['tags'], query: {} });
        } else {
            submittedApplicationStore.updateKeyValue('newSubmittedApplication', {
                applicationOffer: parseInt(applicationOfferId, 10),
                applicationOfferCustomer: appOffer.customer,
                title: `Søknad om: ${appOffer.title}`,
                headerHelpText: appOffer.headerHelpText,
            });
        }
    }

    createSubmittedApplication = async (e) => {
        const { callback = () => {} } = this.props;
        const { submittedApplicationStore } = this.props.stores;
        const { newSubmittedApplication } = submittedApplicationStore;

        const response = await submittedApplicationStore.insert(newSubmittedApplication, e);
        submittedApplicationStore.updateKeyValue('newSubmittedApplication', {});
        const { id } = response.data?.submittedapplication || {};
        callback(id);
    }

    saveSubmittedApplication = async () => {
        const { callback = () => {} } = this.props;
        // const { submittedApplicationStore } = this.props.stores;
        // const { submittedApplication } = submittedApplicationStore;
        // const response = await submittedApplicationStore.update(submittedApplication);

        callback(true);
    }

    toggleFieldFunction = (e) => {
        const { name } = e.target;
        const { toggleFieldValues } = this.state;
        if (toggleFieldValues[name]) {
            toggleFieldValues[name] = 0;
        } else {
            toggleFieldValues[name] = 1;
        }
        this.setState({
            toggleFieldValues
        });

        const hideFields = [];
        Object.keys(toggleFieldValues).forEach(key => {
            if (toggleFieldValues[key] === 1) {
                hideFields.push({
                    name: key,
                    md5: md5(JSON.stringify(`${key}-${util.isoDate()}`)).toString(),
                    date: util.isoDate(),
                });
            }
        });

        const { submittedApplicationStore, applicationOfferStore } = this.props.stores;
        const { applicationOffer } = applicationOfferStore;
        applicationOfferStore.save({
            id: applicationOffer.id,
            [`hideFields-overwrite`]: hideFields,
        });
        submittedApplicationStore.updateKeyValue('hideFields', hideFields);
    }

    toggleSectionFunction = (e) => {
        const { name } = e.target;
        const { toggleSectionValues } = this.state;
        if (toggleSectionValues[name]) {
            toggleSectionValues[name] = 0;
        } else {
            toggleSectionValues[name] = 1;
        }
        this.setState({
            toggleSectionValues
        });

        const hideSections = [];
        Object.keys(toggleSectionValues).forEach(key => {
            if (toggleSectionValues[key] === 1) {
                hideSections.push({
                    key: key,
                    md5: md5(JSON.stringify(`${key}-${util.isoDate()}`)).toString(),
                    date: util.isoDate(),
                });
            }
        });

        const { submittedApplicationStore, applicationOfferStore } = this.props.stores;
        const { applicationOffer } = applicationOfferStore;
        applicationOfferStore.save({
            id: applicationOffer.id,
            [`hideSections-overwrite`]: hideSections,
        });
        submittedApplicationStore.updateKeyValue('hideSections', hideSections);
    }

    openFieldSettings = (params = {}) => {
        const { appState, submittedApplicationStore } = this.props.stores;
        const { drawerLevel = 1, callback = () => {} } = this.props;
        const { drawerHeightSmall } = appState;

        const { appOffer } = submittedApplicationStore;
        const { id } = appOffer;

        appState.openDrawer('editApplicationOfferSettings', {
            ...params,
            id,
            height: drawerHeightSmall,
            callback: async () => {
                await this.loadAppOffer({ ...this.props });
                // callback();
            },
        }, drawerLevel + 1);
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { week, isNew, drawerLevel, applicationOfferId } = this.props;
        const { sections, toggleFieldValues = {}, toggleSectionValues = {} } = this.state;
        const { userStore, submittedApplicationStore } = this.props.stores;
        const { user } = userStore;
        const { isAdmin, isSoknadsguideSuperAdmin } = user;
        const { newSubmittedApplication, hideFields, hideSections, fieldsOpts, appOffer } = submittedApplicationStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        if (!appOffer?.id) {
            // Add centered loading spinner
            return (<>
                <div class='w-100 d-flex justify-content-center'>
                    <div class='w-100 d-flex justify-content-center'>
                        <div class='spinner-border' role='status'>
                            <span class='sr-only'>Loading...</span>
                        </div>
                    </div>
                </div>
            </>);
        }

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    <div class='w-100 d-flex flex-column justify-content-start'>
                        {/* FOO: <xmp>{JSON.stringify(fieldsOpts, null, 2)}</xmp> */}
                        {/* FOO: <xmp>{JSON.stringify(sections, null, 2)}</xmp> */}
                        {/* isAdmin: <xmp>{JSON.stringify(isAdmin, null, 2)}</xmp> */}
                        {/* isSoknadsguideSuperAdmin: <xmp>{JSON.stringify(isSoknadsguideSuperAdmin, null, 2)}</xmp> */}
                    </div>
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    {/* applicationOfferId: {applicationOfferId}<br /> */}
                    {/* appOffer: {JSON.stringify(appOffer, null, 4)}<br /> */}
                    <div class='font-weight-lighter px-3 box-header mt-0 mb-4'>
                        {isNew ? <Text id='submittedApplication.new'>New submitted Application</Text> : <Text id='submittedApplication.edit'>Edit submitted Application</Text>}
                    </div>
                    {submittedApplicationStore.appOffer?.headerHelpText?.length > 0 ? <>
                        <div class='w-100 d-flex flex-column justify-content-start'>
                            <div class='bg-light d-flex flex-column box-container section-button px-3 py-3 border border-white'>
                                <Markdown markdown={submittedApplicationStore.appOffer.headerHelpText} markedOpts={localUtil.getMarkdownOptions()} />
                            </div>
                        </div>
                    </> : <></>}
                    <div class='w-100 d-flex flex-column justify-content-start'>
                        <div class='bg-light d-flex flex-column justify-content-start h-100 pt-4'>
                        {/* hideSections: {JSON.stringify(hideSections, null, 4)}<br /> */}
                        {/* hideFields: {JSON.stringify(hideFields, null, 4)}<br /> */}
                            <FormFields
                                stores={this.props.stores}
                                sections={sections}
                                hideSections={isAdmin || isSoknadsguideSuperAdmin ? [] : hideSections}
                                hideFields={isAdmin || isSoknadsguideSuperAdmin ? [] : hideFields}
                                showRequiredFieldsInfo={true}
                                holdingStore={submittedApplicationStore}
                                objectName={isNew ? 'newSubmittedApplication' : 'submittedApplication'}
                                isNew={isNew}
                                drawerLevel={drawerLevel}
                                isAdmin={false}
                                hideFilters={true}
                                toggleFieldFunction={isAdmin || isSoknadsguideSuperAdmin ? this.toggleFieldFunction : null}
                                toggleFieldValues={toggleFieldValues}
                                toggleSectionFunction={isAdmin || isSoknadsguideSuperAdmin ? this.toggleSectionFunction : null}
                                toggleSectionValues={toggleSectionValues}
                                fieldsOpts={fieldsOpts}
                                openFieldSettings={this.openFieldSettings}
                            />
                        </div>
                    </div>
                    <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={isNew ? this.createSubmittedApplication : this.saveSubmittedApplication}>
                            <i class='fa-duotone fa-floppy-disk mr-2' />
                            {isNew ? <>
                                <Text id='submittedApplication.create'>Create submitted Application</Text>
                            </> : <>
                                <Text id='submittedApplication.save'>Save submitted Application</Text>
                            </>}
                        </button>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default NewSubmittedApplication;
