import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { route } from 'preact-router';
import linkState from 'linkstate';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';

@observer
class Logo extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    scrollToTop = () => {
		const {  refreshPage = () => {} } = this.props;
		const { appState } = this.props.stores;
		appState.appContainerScrollTop();
		refreshPage();
	}

    loadAll = async (props = this.props) => {
        const { appState, customerStore, applicationOfferStore } = this.props.stores;
        const { customer } = customerStore;
        const { customerid } = props;
        if (customerid && customerid !== customer.id) {
            await customerStore.loadPublic(parseInt(customerid, 10));
            const { customerPublic = {} } = customerStore;
            if (customerPublic?.images && customerPublic.images.length > 0) {
                applicationOfferStore.updateKeyValue('customerLogoHeight', 80);
            }
        }
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.customerid !== this.props.customerid) {
            this.loadAll(nextProps);
        }
    }

    render() {
        const { customerid, logoHeight } = this.props;
        const { appState, userStore, customerStore } = this.props.stores;
        const { customerPublic = {} } = customerStore;

        let customerHomePage = `/applicationoffer/customer/${customerPublic?.id}`
        if (customerPublic?.url) {
            customerHomePage = customerPublic.url;
        }


        return (<>
            <a href={customerHomePage}>
                {customerPublic?.images && customerPublic.images.length > 0 && <img src={localUtil.displayImg(customerPublic.images[0], this.props, '220x')} class='img-fluid' style={`max-height: ${logoHeight - 15}px;`} />}
            </a>
        </>);
    }
}

export default Logo;
